import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSettings } from '@hooks/useSettings';
import { useQueryClient } from '@tanstack/react-query';
import { useOptimizely } from '@utils/optimizely/useOptimizely';
import { IkeaEvent } from 'ikea';

import { Item } from 'apis/selling-range';
import useGetCommonVariables from 'apis/useGetCommonVariables';

import { publish, subscribe, unsubscribe } from 'utils/window/pubsub';

type FavouriteItemPayload = {
    productName: string;
    productNumber: string;
    productType: string;
    quantity: string;
    ratings?: number;
};
type FavouritesEvent = IkeaEvent<FavouriteItemPayload>;

export type DesignItem = { configurationId: string; quantity: number };

type ListSubscriptionContextType = {
    isLoading: boolean;
    addItems: (items: Item[], designs?: DesignItem[]) => void;
};

const ListSubscriptionContext = createContext<ListSubscriptionContextType>({
    isLoading: false,
    addItems: () => undefined,
});

export const ListSubscriptionContextProvider = ({
    children,
}: PropsWithChildren) => {
    const queryClient = useQueryClient();
    const commonVariables = useGetCommonVariables();
    const { trackEvent } = useOptimizely();
    const [isLoading, setIsLoading] = useState(false);
    const { listClientId } = useSettings();

    const invalidateLists = useCallback(() => {
        queryClient.invalidateQueries(['GetLists', commonVariables]);
        queryClient.invalidateQueries({ queryKey: ['GetList'], exact: false });
    }, [commonVariables, queryClient]);

    const handleRemoveFromList = useCallback(() => {
        invalidateLists();
        trackEvent('favourites_remove_from_list'); // Optimizely guardrail event
    }, [invalidateLists, trackEvent]);

    const handleAddToList = useCallback(() => {
        invalidateLists();
        trackEvent('favourites_add_to_list'); // Optimizely guardrail event
    }, [invalidateLists, trackEvent]);

    const handleAddItems = useCallback(() => {
        setIsLoading(false);
        invalidateLists();
    }, [invalidateLists]);

    const handleAddItemsFailed = useCallback(() => {
        setIsLoading(false);
    }, []);

    const handleAddItemsInitiated = useCallback(() => {
        setIsLoading(true);
    }, []);

    const addItems = useCallback(
        (items: Item[], designs?: DesignItem[]) => {
            publish('FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES', {
                clientId: listClientId,
                source: 'fav_shared_items',
                items: items.map(item => ({
                    productNumber: item.itemNo,
                    productType: item.itemCommunication.itemKey?.itemType,
                    productName: item.itemCommunication.productNameGlobal,
                    quantity: item.quantity,
                })),
                ...(designs && designs.length > 0
                    ? {
                          designs,
                      }
                    : {}),
            });
        },
        [listClientId]
    );

    useEffect(() => {
        subscribe(
            'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_SUCCESS',
            handleAddToList
        );
        subscribe(
            'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_SUCCESS',
            handleRemoveFromList
        );
        subscribe<FavouritesEvent>(
            'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_SUCCESS',
            handleAddItems
        );
        subscribe<FavouritesEvent>(
            'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_FAIL',
            handleAddItemsFailed
        );
        subscribe<FavouritesEvent>(
            'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_ABORTED',
            handleAddItemsFailed
        );
        subscribe<FavouritesEvent>(
            'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_INITIATE',
            handleAddItemsInitiated
        );
        return () => {
            unsubscribe(
                'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_SUCCESS',
                handleAddToList
            );
            unsubscribe(
                'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_SUCCESS',
                handleRemoveFromList
            );
            unsubscribe(
                'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_SUCCESS',
                handleAddItems
            );
            unsubscribe(
                'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_FAIL',
                handleAddItemsFailed
            );
            unsubscribe(
                'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_ABORTED',
                handleAddItemsFailed
            );
            unsubscribe(
                'FAVOURITE_CLIENT/ADD_ITEMS_TO_FAVOURITES_INITIATE',
                handleAddItemsInitiated
            );
        };
    }, [
        handleAddItems,
        handleAddToList,
        handleRemoveFromList,
        handleAddItemsFailed,
        handleAddItemsInitiated,
    ]);

    const value = useMemo(
        () => ({ isLoading, addItems }),
        [isLoading, addItems]
    );

    return (
        <ListSubscriptionContext.Provider value={value}>
            {children}
        </ListSubscriptionContext.Provider>
    );
};

const useListSubscriptions = () => useContext(ListSubscriptionContext);

export default useListSubscriptions;
