export const publish = (event: string, payload: unknown) => {
    if (typeof window !== 'undefined') {
        window.ikea?.pubsub?.publish(event, payload);
    } else {
        console.warn('detected server, skipping publish');
    }
};

export const subscribe = <T>(
    event: string,
    callback: (
        body: T & { source: string },
        event: {
            body: T & { source: string };
            event: string;
            namespace: string;
            time: number;
        }
    ) => void
) => {
    if (typeof window !== 'undefined') {
        window.ikea?.pubsub?.subscribe(event, callback);
    } else {
        console.warn('detected server, skipping subscribe');
    }
};

export const unsubscribe = (event: string, callback: () => void) => {
    if (typeof window !== 'undefined') {
        window.ikea?.pubsub?.unsubscribe(event, callback);
    } else {
        console.warn('detected server, skipping unsubscribe');
    }
};

export const events = () => {
    if (typeof window !== 'undefined') {
        if (
            window.ikea &&
            window.ikea.pubsub &&
            typeof window.ikea.pubsub.events === 'function'
        ) {
            return window.ikea.pubsub.events();
        } else {
            console.warn('ikea.pubsub or events method not found');
            return [];
        }
    } else {
        console.warn('detected server, skipping events');
        return [];
    }
};
