import { AnalyticsProvider as ActualProvider } from './AnalyticsProvider';
import { NoopComponent } from '@components/Noop';
import { isAnalyticsDisabled } from 'lib/global';

export * from './eventTypes';
export * from './useAnalytics';

export const AnalyticsProvider = isAnalyticsDisabled
    ? NoopComponent
    : ActualProvider;
