import modals from './modals';
import { Modals, ModalState } from './types';

const RootModal = <T extends keyof Modals>({
    name,
    onBack,
    onClose,
    ...state
}: { name: T; onBack: () => void; onClose: () => void } & ModalState<T>) => {
    const Modal = modals[name];
    return (
        <Modal
            key={name}
            onBack={onBack}
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(state as any)}
        />
    );
};

export default RootModal;
