import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { AddToCartPayLoad, CartContextType, CartResponseData } from './types';
import { useModal } from '@components/Modals/useModal';
import useCurrency from '@hooks/useCurrency';
import { publish, subscribe, unsubscribe } from '@utils/window/pubsub';

const CartContext = createContext<CartContextType>({
    isCartLoading: false,
    isCartSuccess: false,
    isCartError: false,
    addToCart: () => undefined,
});

export const CartContextProvider = ({ children }: PropsWithChildren) => {
    const currency = useCurrency();
    const [isCartLoading, setIsCartLoading] = useState(false);
    const [isCartSuccess, setIsCartSuccess] = useState(false);
    const [isCartError, setIsCartError] = useState(false);
    const { showModal } = useModal();

    const handleCartSuccessSubscription = useCallback(
        (data: CartResponseData) => {
            setIsCartLoading(false);
            setIsCartSuccess(true);
            if (data.failedItemNos.length) {
                showModal('AddToCartFailedModal', {
                    failedItemNos: data.failedItemNos,
                    items: data.items,
                });
            }
            setTimeout(() => {
                setIsCartSuccess(false);
            }, 500);
        },
        [showModal]
    );
    const handleCartFailSubscription = () => {
        setIsCartLoading(false);
        setIsCartError(true);
    };

    const addToCart = useCallback(
        ({
            items,
            planners,
            source,
            listId,
            addPartial = false,
        }: AddToCartPayLoad) => {
            //Custom values sent with add_to_cart analytics event
            const customValues =
                listId && items && items.length > 0
                    ? {
                          custom: {
                              list_id: listId,
                              cart_value_local: items
                                  .reduce(
                                      (total, item) =>
                                          total + item.quantity * item.price,
                                      0
                                  )
                                  .toFixed(2),
                          },
                      }
                    : {};
            setIsCartError(false);
            setIsCartSuccess(false);
            setIsCartLoading(true);
            publish('CART_CLIENT/ADD_TO_CART', {
                ...(items && items.length > 0
                    ? {
                          items: items.map(item => ({
                              ...item,
                              productPrice: item.price,
                              currency,
                          })),
                      }
                    : {}),
                ...(planners && planners.length > 0
                    ? {
                          planners,
                      }
                    : {}),
                source,
                ga: customValues,
                addPartial, //This allows for partial adding, meaning re-adding items that didnt fail to add when adding multiple items.
            });
        },
        [currency]
    );

    useEffect(() => {
        subscribe(
            'CART_CLIENT/ADD_TO_CART_SUCCESS',
            handleCartSuccessSubscription
        );
        subscribe('CART_CLIENT/ADD_TO_CART_FAIL', handleCartFailSubscription);
        return () => {
            unsubscribe(
                'CART_CLIENT/ADD_TO_CART_SUCCESS',
                handleCartSuccessSubscription as () => void
            );
            unsubscribe(
                'CART_CLIENT/ADD_TO_CART_FAIL',
                handleCartFailSubscription
            );
        };
    }, [handleCartSuccessSubscription]);

    const value = useMemo(
        () => ({
            isCartError,
            isCartSuccess,
            isCartLoading,
            addToCart,
        }),
        [addToCart, isCartError, isCartLoading, isCartSuccess]
    );

    return (
        <CartContext.Provider value={value}>{children}</CartContext.Provider>
    );
};

const useCart = () => useContext(CartContext);

export default useCart;
