import { Fragment } from 'react';

import { isPlural, ITranslate, Translations } from './types';

const dynamicSegmentPattern = /(\{[^{}]+\})/g;

type Dependencies = {
    locale: string;
    shouldShowPhraseKeys: boolean;
    translations?: Translations;
};

export const createTranslate =
    ({
        locale,
        shouldShowPhraseKeys,
        translations,
    }: Dependencies): ITranslate =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (phraseKey, args, pluralisationAmount = 'one'): any => {
        if (!translations) {
            console.debug(`missing translations for locale: ${locale}`);
            return '';
        }
        const value = translations[phraseKey];
        if (!value) {
            console.debug(`missing translation for key: ${phraseKey}`);
            return '';
        } else if (shouldShowPhraseKeys) {
            return `[${phraseKey}]`;
        }

        let result = value;
        if (isPlural(result)) {
            const pluralValue = result[pluralisationAmount];
            if (!pluralValue) {
                console.debug(
                    `missing pluralisation "${pluralisationAmount}" for key: ${phraseKey}`
                );
                return '';
            }
            result = pluralValue;
        }
        if (!args) {
            return result;
        }

        let isReactElement = false;
        const processed = result
            .split(dynamicSegmentPattern)
            .filter(Boolean)
            .map((segment, i) => {
                if (segment.startsWith('{')) {
                    const key = segment.replace(/[{}]/g, ''); // '{var}' -> 'var'
                    const replacement = args[key];
                    if (!replacement) {
                        return segment;
                    } else if (typeof replacement === 'string') {
                        return replacement;
                    }
                    isReactElement = true;
                    return <Fragment key={i}>{replacement}</Fragment>;
                } else {
                    return segment;
                }
            });
        return isReactElement ? <>{processed}</> : processed.join('');
    };
