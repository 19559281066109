const markets = [
    'atde',
    'aten',
    'auen',
    'been',
    'befr',
    'benl',
    'caen',
    'cafr',
    'chde',
    'chen',
    'chfr',
    'chit',
    'czcs',
    'czen',
    'dede',
    'deen',
    'dkda',
    'esca',
    'esen',
    'eses',
    'eseu',
    'esgl',
    'fifi',
    'fien',
    'frfr',
    'gben',
    'hrhr',
    'huhu',
    'ieen',
    'inen',
    'itit',
    'jpen',
    'jpja',
    'kren',
    'krko',
    'nlen',
    'nlnl',
    'nono',
    'plpl',
    'pten',
    'ptpt',
    'roro',
    'rssr',
    'sesv',
    'seen',
    'sisl',
    'sksk',
    'usen',
    'uses',
] as const;

export default markets;
