// Extract all postal codes and insert into an Array.
// const sampleObject = [...]
// Data: https://www.ikea.com/us/en/meta-data/navigation/stores-detailed.json

/**
 * Example:
 *
 * const marketPostalCodes = [];
 * for (const obj of sampleObject) {
 *     if (obj.buClassification.name === 'IKEA STORE') {
 *         marketPostalCodes.push(obj.address.zipCode);
 *     }
 * }
 */

const DE = [
    '01139',
    '06237',
    '09116',
    '10365',
    '10829',
    '12529',
    '13597',
    '18069',
    '22113',
    '22457',
    '22767',
    '23569',
    '24114',
    '26135',
    '27572',
    '28816',
    '30539',
    '30938',
    '33647',
    '34123',
    '35576',
    '38112',
    '39128',
    '40589',
    '41564',
    '42279',
    '44149',
    '45127',
    '47166',
    '49078',
    '50829',
    '50997',
    '56070',
    '57072',
    '59174',
    '60437',
    '63452',
    '65719',
    '66740',
    '67663',
    '68307',
    '69190',
    '71063',
    '71634',
    '76137',
    '79108',
    '82024',
    '85386',
    '86368',
    '89077',
    '90765',
    '93055',
    '97078',
    '99094',
];

const US = [
    '02072',
    '06511',
    '07201',
    '07652',
    '11231',
    '11801',
    '15205',
    '19148',
    '19428',
    '20740',
    '21236',
    '22192',
    '23502',
    '28262',
    '30363',
    '32256',
    '32839',
    '33172',
    '33325',
    '33605',
    '38016',
    '43240',
    '45069',
    '46037',
    '48187',
    '53154',
    '55425',
    '60173',
    '60440',
    '63108',
    '66202',
    '75034',
    '75052',
    '77024',
    '78233',
    '78665',
    '80112',
    '84020',
    '85284',
    '89148',
    '90746',
    '91502',
    '91723',
    '92108',
    '92626',
    '94303',
    '94608',
    '95605',
    '97220',
    '98057',
];

const SE = [
    '14175',
    '17738',
    '25469',
    '34333',
    '39239',
    '42246',
    '42836',
    '45176',
    '55305',
    '58111',
    '65346',
    '70231',
    '72138',
    '75323',
    '78170',
    '81832',
    '85753',
    '90422',
    '95336',
];

const PL = [
    '85-776',
    '80-298',
    '40-203',
    '31-357',
    '20-147',
    '93-457',
    '61-285',
    '71-010',
    '05-090',
    '03-286',
    '55-040',
];

const GB = [
    'BT3 9EJ',
    'BS5 6XX',
    'CF11 0XR',
    'CR0 4YA',
    'EH20 9BY',
    'EX2 7RX',
    'NE11 9XS',
    'G51 4FB',
    'SE10 0QJ',
    'RM20 3WJ',
    'WF17 9AE',
    'OL6 7TE',
    'MK1 1QB',
    'NG16 2RP',
    'RG31 7SD',
    'S9 2YL',
    'SO15 1GY',
    'WA5 7TY',
    'WS10 9SF',
    'NW10 0TH',
];

const IT = [
    '60021',
    '70126',
    '40033',
    '25030',
    '95121',
    '66020',
    '50019',
    '16152',
    '34070',
    '20061',
    '20094',
    '20098',
    '80021',
    '35129',
    '43122',
    '56121',
    '47922',
    '00173',
    '00139',
    '84081',
    '10093',
];

const ES = [
    '15008',
    '28922',
    '33429',
    '08917',
    '48902',
    '28051',
    '08908',
    '11408',
    '29004',
    '30110',
    '08205',
    '28701',
    '41950',
    '46910',
    '47195',
    '50021',
];

const CA = [
    'J4B 5E4',
    'L7T 4K1',
    'T2H 3B2',
    'V3K 3T5',
    'T6N 1M3',
    'M8Z 1T3',
    'B3B 0M2',
    'H4T 1M8',
    'M2K 2X9',
    'K2C 3S4',
    'G1X 0E4',
    'V6V 1Z6',
    'L4K 5C3',
    'R3P 1E7',
];

const JP = [
    '982-0007',
    '341-0009',
    '273-0012',
    '190-0014',
    '224-0043',
    '480-1332',
    '551-0023',
    '650-0046',
    '811-0120',
];

const NL = [
    '3825 HB',
    '1101 BL',
    '2993 LG',
    '4814 RW',
    '2616 LN',
    '6921 EV',
    '5692 DB',
    '9723 AT',
    '2031 CX',
    '6412 PM',
    '7559 SR',
    '3526 AP',
    '8028 PN',
];

const NO = ['4313', '1081', '7048', '2320', '1396', '4636', '5131'];

const HU = ['2040', '1148', '1238'];

const AU = [
    '5950',
    '2609',
    '4127',
    '2765',
    '6018',
    '4509',
    '2138',
    '3121',
    '3171',
    '2044',
];

const DK = ['2820', '5220', '2630', '9200', '8200'];

const PT = ['2614-520', '4700-068', '8135-182', '2660-001', '4450-745'];

const RO = ['013696', '032266', '307160'];

const CH = [
    '1170',
    '8305',
    '6916',
    '3421',
    '4133',
    '6023',
    '8957',
    '9015',
    '1214',
];

const CZ = ['619 00', '198 00', '700 30', '155 00'];

const HR = ['10361'];

const BE = ['1070', '6700', '9051', '3500', '4342', '7000', '2610', '1930'];

const AT = ['8054', '6020', '9020', '4053', '5020', '2334', '1220'];

export const getStorePostalCodesByMarket = (country: string) => {
    if (country === 'de') {
        return DE;
    }
    if (country === 'se') {
        return SE;
    }
    if (country === 'us') {
        return US;
    }
    if (country === 'pl') {
        return PL;
    }
    if (country === 'gb') {
        return GB;
    }
    if (country === 'it') {
        return IT;
    }
    if (country === 'es') {
        return ES;
    }
    if (country === 'ca') {
        return CA;
    }
    if (country === 'jp') {
        return JP;
    }
    if (country === 'nl') {
        return NL;
    }
    if (country === 'no') {
        return NO;
    }
    if (country === 'hu') {
        return HU;
    }
    if (country === 'au') {
        return AU;
    }
    if (country === 'dk') {
        return DK;
    }
    if (country === 'pt') {
        return PT;
    }
    if (country === 'ro') {
        return RO;
    }
    if (country === 'ch') {
        return CH;
    }
    if (country === 'cz') {
        return CZ;
    }
    if (country === 'hr') {
        return HR;
    }
    if (country === 'be') {
        return BE;
    }
    if (country === 'at') {
        return AT;
    }
};
