import { extractMarketNameFromPath } from '@utils/extractMarketNameFromPath';
import { defaultMarketName, isMarketName, MarketName } from 'lib/global';
import { GetStaticPropsContext, NextPageContext } from 'next';
import { ParsedUrlQuery } from 'querystring';

const defaultCountryKey = 'cc';
const defaultLanguageKey = 'll';

interface NextContextParserCreator {
    (options: { countryKey: string; languageKey: string }): <
        T extends ParsedUrlQuery
    >(
        ctx:
            | Pick<NextPageContext, 'query' | 'asPath'>
            | GetStaticPropsContext<T>
    ) => MarketName;
}

/** Parses the marketName from different types of NextJS context. */
const createNextContextParser: NextContextParserCreator =
    ({ countryKey, languageKey }) =>
    ctx => {
        let queryString = '';
        if (
            'params' in ctx &&
            ctx?.params?.[countryKey] &&
            ctx?.params?.[languageKey]
        ) {
            queryString = `${ctx?.params?.[countryKey]}${ctx?.params?.[languageKey]}`;
        } else if (
            'query' in ctx &&
            ctx?.query?.[countryKey] &&
            ctx?.query?.[languageKey]
        ) {
            queryString = `${ctx?.query?.[countryKey]}${ctx?.query?.[languageKey]}`;
        } else if ('asPath' in ctx && typeof ctx.asPath === 'string') {
            queryString = extractMarketNameFromPath(ctx?.asPath);
        }
        if (isMarketName(queryString)) {
            return queryString;
        }
        return defaultMarketName;
    };

export default createNextContextParser({
    countryKey: defaultCountryKey,
    languageKey: defaultLanguageKey,
});
