import { useCallback, useEffect, useMemo, useState } from 'react';

import { ToastProps } from '@ingka/toast';

const TOAST_TIMEOUT = 4000;

type ToastMessage = {
    name: string;
    text: string | JSX.Element;
    onTimeout?: () => void;
} & Pick<ToastProps, 'actionClick' | 'actionButtonText'>;

const useToast = () => {
    const [toast, setToast] = useState(null as ToastMessage | null);

    useEffect(() => {
        let timeoutId: number;
        if (typeof window === 'undefined') {
            return;
        }
        if (toast && typeof window !== 'undefined') {
            timeoutId = window.setTimeout(() => {
                if (toast.onTimeout) {
                    toast.onTimeout();
                }
                setToast(null);
            }, TOAST_TIMEOUT);
        }
        return () => clearTimeout(timeoutId);
    }, [toast]);

    const showToast = useCallback(
        function showToast(options: ToastMessage) {
            if (toast?.name !== options.name && toast?.onTimeout) {
                toast.onTimeout();
            }
            setToast(options);
        },
        [toast]
    );

    const removeToast = useCallback(function removeToast() {
        setToast(null);
    }, []);

    const useToast = useMemo(
        () => ({
            showToast,
            removeToast,
            toast,
        }),
        [showToast, removeToast, toast]
    );

    return useToast;
};

export default useToast;
