import { useEffect } from 'react';

import useAuth from './useAuth';
import useLocale from './useLocale';
import useMoveUser from './useMoveUser';

import { getCookie, setCookie } from 'utils/cookie';

type Token = {
    value: string;
    expirationTime: number;
};

const useMoveFromGuestToLoggedInUser = () => {
    const { isLoggedIn, tokens } = useAuth();
    const { country, language } = useLocale();
    const { moveFromGuestToLoggedInUser } = useMoveUser();

    useEffect(() => {
        const checkAreTokensOutOfSync = async () => {
            if (isLoggedIn && tokens && tokens.guest && tokens.auth) {
                const authToken = tokens.auth as Token;
                const currentAccessTokenExpiry =
                    authToken.expirationTime.toString();
                const savedAccessTokenExpiry = getCookie(
                    'favouritesStoredAccessTokenExpiry'
                );

                const areTokensOutOfSync =
                    currentAccessTokenExpiry !== savedAccessTokenExpiry;
                if (areTokensOutOfSync) {
                    try {
                        moveFromGuestToLoggedInUser(tokens.guest as Token);
                        setCookie(
                            'favouritesStoredAccessTokenExpiry',
                            country,
                            language,
                            currentAccessTokenExpiry
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        };

        checkAreTokensOutOfSync();
    }, [country, isLoggedIn, language, moveFromGuestToLoggedInUser, tokens]);
};

export default useMoveFromGuestToLoggedInUser;
