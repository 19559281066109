import { useMemo } from 'react';

import useLocale from '../useLocale';
import { getFiles } from './getFiles';

export type SettingsObject = {
    baseUrl: string;
    browseProductsUrl: string;
    compareUrl: string;
    ecoFeeParticipationUrl: string;
    enableAlternativeAvailabilityCheck: boolean;
    esiBase: string;
    familyRewardKeysUrl: string;
    familySignupUrl: string;
    familyUpgradeUrl: string;
    getStoresUrl: string;
    giftRegistryClientId: string;
    giftRegistryEndpoint: string;
    giftRegistryManageUrl: string;
    hideAddToCart: boolean;
    includeVAT: boolean;
    kompassEnpoint: string;
    listClientId: string;
    listEndpoint: string;
    loginUrl: string;
    maxItems: number;
    maxListNameLength: number;
    maxLists: number;
    measurement: 'metric' | 'imperial' | 'both';
    optimizelyBase: string;
    optimizelyKey: string;
    optimizelyKeySecondary: string;
    pipUrl: string;
    profileClientId: string;
    profileEndpoint: string;
    publicUrl: string;
    shareUrl: string;
    showClickAndCollect: boolean;
    showDevModal: boolean;
    showDisplayUnit: boolean;
    showEcoFee: boolean;
    showVAT: boolean;
    signupUrl: string;
    sunClientId: string;
    sunEnable: boolean;
    sunEndpoint: string;
    sunPrivacyPolicyLink: string;
    urlBaseName: string;
    websiteBase: string;
};

export const useSettings = (): SettingsObject => {
    const localeData = useLocale();
    return useMemo(
        () => getFiles(localeData.ikeaMarketName),
        [localeData.ikeaMarketName]
    );
};
