import React, { useState } from 'react';

import '../styles/globals.scss';
import ErrorBoundary from '@components/ErrorBoundary';
import { ModalProvider } from '@components/Modals/ModalContext';
import { ReactQueryDevtools } from '@components/ReactQueryDevtools';
import { MarketNameProvider } from '@hooks/useMarketName';
import { PrintContextProvider } from '@hooks/usePrint';
import SentryRenderer from '@hooks/useSentry';
import {
    DehydratedState,
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { AnalyticsProvider } from '@utils/analytics';
import { OptimizelyProvider } from '@utils/optimizely';
import parseNextContext from '@utils/parseNextContext';
import { TranslationsContextProvider } from 'context/Translations';
import { UserPreferencesProvider } from 'context/UserPreferences';
import { MarketName, shouldMockProd } from 'lib/global';
import App, { AppContext, AppProps } from 'next/app';

import { CartContextProvider } from 'apis/cart/useCart';
import { ListSubscriptionContextProvider } from 'apis/list/hooks/useListSubscriptions';

import PageContainer from 'skapa/PageContainer';

import ComponentWrapper from 'components/ComponentWrapper';
import { ToastProvider } from 'components/Toast';
import ToastNotifications from 'components/ToastNotifications';

if (shouldMockProd) {
    import('@utils/mock-consent').then(({ mockConsent }) => {
        mockConsent();
    });
}

const CustomApp = ({
    Component,
    pageProps,
    err, // This is not public Nextjs API...
    router,
    marketName,
}: AppProps & { marketName: MarketName } & {
    err: unknown;
    pageProps: AppProps['pageProps'] & { dehydratedState: DehydratedState };
}) => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false, // Since we're anyway reacting to changes through pubsub events.
                    },
                },
            })
    );
    return (
        <MarketNameProvider marketName={marketName}>
            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps?.dehydratedState}>
                    <TranslationsContextProvider>
                        <ErrorBoundary router={router}>
                            <ReactQueryDevtools />
                            <UserPreferencesProvider>
                                <ToastProvider>
                                    <AnalyticsProvider>
                                        <OptimizelyProvider>
                                            <PageContainer>
                                                <ToastNotifications />
                                                <PrintContextProvider>
                                                    <ListSubscriptionContextProvider>
                                                        <ModalProvider>
                                                            <CartContextProvider>
                                                                <ComponentWrapper
                                                                    router={
                                                                        router
                                                                    }
                                                                >
                                                                    <Component
                                                                        {...pageProps}
                                                                        err={
                                                                            err
                                                                        }
                                                                        router={
                                                                            router
                                                                        }
                                                                    />
                                                                </ComponentWrapper>
                                                            </CartContextProvider>
                                                        </ModalProvider>
                                                    </ListSubscriptionContextProvider>
                                                </PrintContextProvider>
                                            </PageContainer>
                                        </OptimizelyProvider>
                                    </AnalyticsProvider>
                                </ToastProvider>
                            </UserPreferencesProvider>
                        </ErrorBoundary>
                    </TranslationsContextProvider>
                </Hydrate>
            </QueryClientProvider>
            <SentryRenderer />
        </MarketNameProvider>
    );
};

CustomApp.getInitialProps = async (
    appContext: AppContext
): Promise<{ marketName: string }> => {
    const initialProps = await App.getInitialProps(appContext);
    const marketName = parseNextContext(appContext.ctx);
    return {
        ...initialProps,
        marketName,
    };
};

export default CustomApp;
