import { useEffect, useState } from 'react';

import {
    EXPERIMENT_KEY,
    initialTimeoutVariations,
    NEXT_PROMPT_MS,
    RETRY_TIMEOUT_SEC,
    STORE_INTENT_PROMPT_DATE_KEY,
} from './constants';
import { useModal } from '@components/Modals/useModal';
import { useOptimizely } from '@utils/optimizely/useOptimizely';

export const useStoreIntentPrompt = () => {
    const { showModal } = useModal();
    const { decide } = useOptimizely();
    const [time, setTime] = useState<{ time?: number }>({
        time: undefined, // Making this an object to ensure hook re-runs on value change.
    });
    const variationKey = (decide(EXPERIMENT_KEY)?.variationKey ||
        '') as keyof typeof initialTimeoutVariations;

    useEffect(() => {
        const currTimeoutSec =
            time.time || initialTimeoutVariations[variationKey];
        if (!currTimeoutSec) {
            return;
        }

        const promptDate = localStorage.getItem(STORE_INTENT_PROMPT_DATE_KEY);
        const now = Date.now();
        const parsedDate = promptDate ? Number.parseInt(promptDate, 10) : now;

        let timeout: NodeJS.Timeout;
        if (now >= parsedDate) {
            timeout = setTimeout(() => {
                // Avoid prompting the user if there's a modal open. Instead retry in 10 seconds.
                const isDialogOpen =
                    document.querySelector('[role="dialog"]') !== null;
                if (isDialogOpen) {
                    return setTime({ time: RETRY_TIMEOUT_SEC });
                }
                const nextPromptDate = parsedDate + NEXT_PROMPT_MS;
                localStorage.setItem(
                    STORE_INTENT_PROMPT_DATE_KEY,
                    nextPromptDate.toString()
                );
                showModal('StoreIntentPrompt', {});
            }, currTimeoutSec * 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [showModal, time, variationKey]);
};
