const regex = /^ \(\d+\)$/;

export const createListNameFormatter =
    (defaultName = '') =>
    (name = '') => {
        if (!name) {
            return defaultName;
        }
        // E.g. " (1)". This name arises when a default list has been copied over from an guest account to a logged in user.
        if (regex.test(name)) {
            return defaultName + name;
        }
        return name;
    };
