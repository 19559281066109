import { GetListQuery, GetListsQuery } from './list/reactQueryHooks';
import { isObject } from '@utils/isObject';

export type GQLErrors = Array<{
    extensions: {
        code: string;
    };
    message: string;
}>;

const isGQLError = (arg: unknown): arg is GQLErrors[number] => {
    if (!isObject(arg)) {
        return false;
    }
    if (!('extensions' in arg)) {
        return false;
    }
    if (!('message' in arg)) {
        return false;
    }
    if (!isObject(arg.extensions)) {
        return false;
    }
    if (!('code' in arg.extensions)) {
        return false;
    }
    return true;
};

export const isGQLErrors = (arg: unknown): arg is GQLErrors => {
    if (!Array.isArray(arg)) {
        return false;
    }
    if (!arg.every(isGQLError)) {
        return false;
    }
    return true;
};

type GenericError = {
    code: ListApiErrorCode | CartApiErrorCode;
    data?: Record<string, unknown>;
};

// Omit these common variables, we provide them inside the hooks instead
export type OmitCommon<T> = Omit<T, keyof CommonVariables>;

export type CommonVariables = {
    languageCode: string;
    storeId: string | null;
    withStore: boolean;
    zip: string | null;
};

export type Lists = GetListsQuery['lists'];
export type List = GetListQuery['list'];
export type Items = Lists[number]['items'];
export type Designs = Lists[number]['designs'];
export type SourceList = Lists[number];

export type CartError = Omit<GenericError, 'data'> & {
    data: {
        itemNos?: string[];
    };
};

export type ListApiErrorCode =
    | 'ILLEGAL_QUANTITY'
    | 'INTERNAL_ERROR'
    | 'PERMISSION_DENIED'
    | 'INVALID_ITEM_NUMBER'
    | 'INVALID_STORE_ID'
    | 'ITEM_NUMBER_NOT_UNIQUE'
    | 'ITEM_NUMBER_NOT_FOUND'
    | 'NAME_CONFLICT'
    | 'EMPTY_NAME'
    | 'MAX_NAME_LENGTH'
    | 'LIST_NOT_FOUND'
    | 'NOT_IMPLEMENTED'
    | 'MAX_CREATE_LIMIT'
    | 'NETWORK_ERROR';

export type CartApiErrorCode =
    | 'ILLEGAL_QUANTITY' // Illegal quantity in request.
    | 'INTERNAL_ERROR' // An internal error has occurred.
    | 'INVALID_ITEM_NUMBER' // Invalid or unavailable item number(s) in request.
    | 'ITEM_NUMBER_NOT_UNIQUE' // Identical item numbers in request.
    | 'NETWORK_ERROR';
