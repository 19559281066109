// Determines the timeout before prompting users (in seconds)
// Avg. time spent on a page is about 90 sec.
export const initialTimeoutVariations = {
    a: 10,
    b: 20,
    c: 30,
    d: 60,
    e: 75,
} as const satisfies Record<string, number>;

export const STORE_INTENT_PROMPT_DATE_KEY =
    'favourites/store_intent_prompt_date';
export const EXPERIMENT_KEY = 'store_intent_prompt';
export const RETRY_TIMEOUT_SEC = 5;
export const NEXT_PROMPT_MS = 1000 * 60 * 60 * 24 * 7.5; // one week + one day
