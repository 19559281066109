import { ikeaEnvironments } from './constants';
import markets from './markets';
import { IkeaEnv, MarketName } from './types';

const marketsSet = new Set(markets);
export const isMarketName = (arg: unknown): arg is MarketName =>
    typeof arg === 'string' && marketsSet.has(arg as MarketName);

export const isIkeaEnv = (arg: unknown): arg is IkeaEnv => {
    if (typeof arg === 'string' && ikeaEnvironments.includes(arg as IkeaEnv)) {
        return true;
    }
    return false;
};
