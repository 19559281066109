import { useEffect, useState } from 'react';

import useSetDevCookies from './useSetDevCookies';
import { useToast } from '@components/Toast';
import { useQuery } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';

export type Auth = {
    tokens: {
        auth?: {
            value: string;
            expirationTime: number;
        } | null;
        guest?: {
            value: string;
            expirationTime: number;
        } | null;
    };
    isLoggedIn?: boolean;
    isFamilyMember?: boolean;
    accessToken?: string | null; // Should either be auth or guest
};

export enum LoyaltyPrograms {
    IKEA_FAMILY = 'IKEA_FAMILY',
}

type CustomerType = 'individual' | 'business' | 'employee' | '';

const getCustomerType = (
    decodedToken: Record<string, string> = {}
): CustomerType => {
    return (
        (decodedToken[
            'https://accounts.ikea.com/customerType'
        ] as CustomerType) || ''
    );
};

const isFamilyMember = (decodedToken: Record<string, string> = {}): boolean => {
    const loyaltyPrograms =
        decodedToken['https://accounts.ikea.com/loyaltyPrograms'];
    if (Array.isArray(loyaltyPrograms)) {
        return loyaltyPrograms.includes(LoyaltyPrograms.IKEA_FAMILY);
    } else {
        return false;
    }
};

const useAuth = () => {
    const setDevCookies = useSetDevCookies();
    const [errorMessage, setErrorMessage] = useState('');
    const { showToast } = useToast();
    const { data, refetch, isLoading, isFetching } = useQuery(
        ['auth'],
        (): Promise<Auth> => {
            return new Promise<Auth>((resolve, reject) => {
                if (typeof window !== 'undefined') {
                    if (!window.ikea.authentication) {
                        const message = 'window.ikea.authentication is missing';
                        showToast({
                            name: 'useAuth',
                            text: message,
                        });
                        return reject(message);
                    }
                    return window.ikea.authentication.getTokens(
                        (err: Error, tokens: Auth['tokens']) => {
                            if (err) {
                                let message = '';
                                if (typeof err === 'string') {
                                    message = err;
                                }
                                if (err instanceof Error) {
                                    message = err.message;
                                }
                                setErrorMessage(message);
                                return reject(message);
                            }
                            // TODO: Set this inside an if statement
                            setDevCookies(tokens);
                            return resolve({
                                tokens,
                                accessToken:
                                    tokens.auth?.value || tokens.guest?.value,
                                isLoggedIn: !!tokens.auth,
                            });
                        }
                    );
                }
                return resolve({ tokens: {} });
            });
        }
    );

    useEffect(() => {
        if (!data?.accessToken && !errorMessage && !isLoading && !isFetching) {
            refetch();
        }
    }, [refetch, data?.accessToken, errorMessage, isLoading, isFetching]);

    const decodedToken = data?.accessToken
        ? jwt_decode<Record<string, string>>(data?.accessToken)
        : {};

    return {
        error: errorMessage,
        tokens: data?.tokens,
        accessToken: data?.accessToken,
        isLoggedIn: data?.isLoggedIn,
        isFamilyMember: isFamilyMember(decodedToken),
        reload: refetch,
        customerType: getCustomerType(decodedToken),
        isAuthenticating: isLoading,
    };
};

export default useAuth;
