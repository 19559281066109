import { ErrorCategory } from './types';
import { PhraseTranslationKeys } from '@utils/get-message';

/**
 *  Defines the default translation string for each category.
 *  This is used when no specific translation exists for a particular error code.
 */
export const defaultErrorsTranslationCodeMap: Record<
    ErrorCategory,
    PhraseTranslationKeys
> = {
    CartError: 'GENERIC_ERROR_TOAST',
    FavouritesError: 'GENERIC_ERROR_TOAST',
    GiftRegistryError: 'GENERIC_ERROR_TOAST',
    RatingError: 'GENERIC_ERROR_TOAST',
    SellingRangeError: 'GENERIC_ERROR_TOAST',
    SunError: 'GENERIC_ERROR_TOAST',
    UnknownError: 'GENERIC_ERROR_TOAST',
};

/** Maps error codes for different error categories to translations strings. */
export const translationCodeMap: Record<
    ErrorCategory,
    Record<string, PhraseTranslationKeys>
> = {
    CartError: {},
    FavouritesError: {},
    GiftRegistryError: {},
    RatingError: {},
    SellingRangeError: {},
    SunError: {},
    UnknownError: {},
};
