import React from 'react';

import { ReactQueryDevtools as RQDevTools } from '@tanstack/react-query-devtools';

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
        d => ({
            default: d.ReactQueryDevtools,
        })
    )
);

export function ReactQueryDevtools() {
    const [showDevtools, setShowDevtools] = React.useState(false);

    React.useEffect(() => {
        window.toggleDevtools = () => setShowDevtools(old => !old);
    }, []);

    return (
        <>
            <RQDevTools initialIsOpen={false} position="bottom-right" />
            {showDevtools && (
                <React.Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction />
                </React.Suspense>
            )}
        </>
    );
}
