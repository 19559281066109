/* eslint-disable no-redeclare,@typescript-eslint/no-explicit-any */
import { GetListsQuery, useGetListsQuery } from '../reactQueryHooks';
import useAuth from '@hooks/useAuth';
import useListNameFormatter from '@hooks/useListNameFormatter';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { SourceList } from 'apis/types';
import useGetCommonVariables from 'apis/useGetCommonVariables';

type QueryData = GetListsQuery | SourceList | undefined;

type Options<T = any> = {
    isSelectedOnly?: boolean; // Returns only the selected list.
} & UseQueryOptions<T>;

const withNameFormatter =
    (formatter: (name?: string) => string) =>
    (data: GetListsQuery): GetListsQuery => ({
        ...data,
        lists: data.lists.map(list => ({
            ...list,
            name: formatter(list.name),
        })),
    });

const withSelectedOrFirst = (data: GetListsQuery) =>
    data?.lists?.find(l => l.isSelected) || data?.lists[0];

function useGetLists(
    opts?: {
        isSelectedOnly?: false;
    } & UseQueryOptions<GetListsQuery>
): UseQueryResult<GetListsQuery>;

function useGetLists(
    opts?: {
        isSelectedOnly?: true;
    } & UseQueryOptions<SourceList>
): UseQueryResult<SourceList>;

function useGetLists(
    { isSelectedOnly = false, enabled = true, ...options }: Options = {
        isSelectedOnly: false,
        enabled: true,
    }
): any {
    const { accessToken } = useAuth();
    const formatListName = useListNameFormatter();
    const commonVars = useGetCommonVariables();
    return useGetListsQuery<QueryData>(commonVars, {
        ...options,
        enabled: !!accessToken && enabled,
        keepPreviousData: true,
        select: data => {
            const formattedData = withNameFormatter(formatListName)(
                options.select?.(data) || data
            );
            if (isSelectedOnly) {
                return withSelectedOrFirst(formattedData);
            }
            return formattedData;
        },
    });
}

export default useGetLists;
