import { SettingsObject } from '@hooks/useSettings';
import { getMarketData } from '@utils/getMarketData';
import { MarketName } from 'lib/global';

export const cteDefaults = (market: MarketName): Partial<SettingsObject> => {
    const { country, language } = getMarketData(market);
    const websiteBase = 'https://www.cte.ikeadt.com';
    return {
        baseUrl: 'https://www.cte.ikeadt.com',
        giftRegistryEndpoint: 'https://igift.test.ingkadt.com/graphql',
        kompassEnpoint: 'https://metro.ikeadt.net',
        listClientId: '14adf7f9-23af-480e-98fd-8131e423daea',
        listEndpoint: 'https://dev.favs.oneweb.ingkadt.com/graphql',
        optimizelyBase: 'https://www.cte.ikeadt.com',
        optimizelyKey: 'CwVNQ9rGUcVFbEJYcfZzv',
        optimizelyKeySecondary: 'GRmjFwNPRsL2xKS93o7jt',
        profileClientId: '659e0e38-0474-4276-b647-9fa0eb33e982',
        profileEndpoint: 'https://api.ingka.ppe.ikeadt.com/api/profile/icm',
        showDevModal: true,
        sunClientId: '7dbe0b5a-47d8-472e-9205-f79d478697ef',
        sunEndpoint: 'https://api.ingka.ppe.ikeadt.com/sun/signup',
        sunPrivacyPolicyLink: `${websiteBase}/${country}/${language}/customer-service/privacy-policy/`,
        websiteBase,
    };
};
