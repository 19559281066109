import {
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';

import { useFetchData } from 'apis/list/fetcher';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
};

/** The necessary input when adding items. */
export type AddDesignInput = {
    /** The IKEA item number, e.g. "00263850" */
    configurationId: Scalars['ID']['input'];
    /** The quantity of items. */
    quantity: Scalars['Int']['input'];
};

/** The necessary input when adding items. */
export type AddItemInput = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['input'];
    /** The quantity of items. */
    quantity: Scalars['Int']['input'];
};

/** The article. */
export type Article = {
    __typename?: 'Article';
    /** Is item collected? */
    isCollected: Scalars['Boolean']['output'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Storage location in store. Requires storeId in itemPresentation input. */
    location: Maybe<Location>;
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int']['output'];
    /** Weight per article. */
    weight: Weight;
};

/** Article sorting options. */
export enum ArticleOrder {
    /** Sort articles by itemNo. */
    ItemNo = 'ITEM_NO',
    /**
     * Sorts by location in store, moving articles with department to the front
     * of the list and then two stage sort on bin and aisle.
     * Requires storeId in itemPresentation input.
     */
    Location = 'LOCATION',
}

/** Item availability */
export type Availability = {
    __typename?: 'Availability';
    /** Availability information for Cash'n'Carry. Requires storeId in itemPresentation input. */
    cashCarry: Maybe<BuyingOption>;
    /** Availability information for Click & Collect Requires storeId in itemPresentation input. */
    clickCollect: Maybe<BuyingOption>;
    /** Availability information for Home Delivery. */
    homeDelivery: Maybe<BuyingOption>;
};

/** The base interface and common denominator for an article. */
export type BaseArticle = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int']['output'];
    /** Weight per article. */
    weight: Weight;
};

/** The base interface and common denominator for an item. */
export type BaseItem = {
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int']['output'];
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean']['output'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean']['output'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean']['output'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean']['output'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int']['output'];
    /** The regular price. */
    regularPrice: Maybe<RegularPrice>;
    /** The item type */
    type: ItemType;
};

export type BusinessStructure = {
    __typename?: 'BusinessStructure';
    homeFurnishingBusinessName: Maybe<Scalars['String']['output']>;
    homeFurnishingBusinessNo: Maybe<Scalars['String']['output']>;
};

/** Information about a way of buying an item */
export type BuyingOption = {
    __typename?: 'BuyingOption';
    /** Tells if the item can be bought this way */
    available: Scalars['Boolean']['output'];
    /** Type of message for end-user e.g. "HIGH_IN_STOCK", "OUT_OF_STOCK" */
    messageType: Maybe<Scalars['String']['output']>;
    /** Available quantity */
    quantity: Maybe<Scalars['Int']['output']>;
    /** Restocks data, a.k.a estimated back in stock. */
    restocks: Array<Restock>;
};

/** The context type. */
export type Context = {
    __typename?: 'Context';
    /** Is user anonymous or not? */
    isAnonymous: Scalars['Boolean']['output'];
    /** The request id. */
    requestId: Scalars['String']['output'];
    /** The user id. */
    userId: Scalars['ID']['output'];
};

/** The department in the store where an item can be found. */
export type Department = {
    __typename?: 'Department';
    /** The id of the department. */
    id: Scalars['String']['output'];
    /** The name. */
    name: Scalars['String']['output'];
};

/** The depot location where an item can be found in store. */
export type Depot = {
    __typename?: 'Depot';
    /** The aisle location. */
    aisle: Scalars['String']['output'];
    /** The bin location. */
    bin: Scalars['String']['output'];
};

/** The design. */
export type Design = {
    __typename?: 'Design';
    /** UNIX time in seconds for when the design was added to the list. */
    addedAt: Scalars['Int']['output'];
    /** From what design application is the design. */
    application: Scalars['String']['output'];
    /** An alphanumeric 6 character length code. */
    configurationId: Scalars['ID']['output'];
    /** The design images. */
    images: Array<DesignImage>;
    /** A list of all items in the design. */
    items: Array<Item>;
    links: Maybe<DesignLinks>;
    prices: DesignPricePerSalesChannel;
    /** An alphanumeric 6 character length code. */
    quantity: Scalars['Int']['output'];
};

export type DesignImage = {
    __typename?: 'DesignImage';
    typeCode: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type DesignLinks = {
    __typename?: 'DesignLinks';
    deepLink: Scalars['String']['output'];
    shortUrl: Scalars['String']['output'];
};

/** Price for the design */
export type DesignPrice = {
    __typename?: 'DesignPrice';
    /** The family price. */
    family: Maybe<FamilyPrice>;
    /** The regular price */
    regular: Maybe<RegularPrice>;
};

/** The prices for the sales channels */
export type DesignPricePerSalesChannel = {
    __typename?: 'DesignPricePerSalesChannel';
    online: Maybe<DesignPrice>;
    store: Maybe<DesignPrice>;
};

/** The discount. */
export type Discount = {
    __typename?: 'Discount';
    /** The discount amount. */
    amount: Scalars['Float']['output'];
    /** The discount code. */
    code: Maybe<Scalars['String']['output']>;
    /** The discount description. */
    description: Maybe<Scalars['String']['output']>;
    /** The discount kind. */
    kind: DiscountKind;
};

/** The discount kind. */
export enum DiscountKind {
    /** Item discount. */
    Item = 'ITEM',
    /** Order discount. */
    Order = 'ORDER',
}

/** The display unit. */
export type DisplayUnit = {
    __typename?: 'DisplayUnit';
    /** The imperial measurement details. */
    imperial: Maybe<Imperial>;
    /** The metric measurement details. */
    metric: Maybe<Metric>;
    /** The unit type of the package, e.g "Piece", "Meterware". */
    type: UnitType;
};

/** The family price. */
export type FamilyPrice = {
    __typename?: 'FamilyPrice';
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The unit details. */
    unit: FamilyUnitPrice;
};

/** The family total price. */
export type FamilyTotalPrice = {
    __typename?: 'FamilyTotalPrice';
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
    /** The total savings excluding discount. */
    totalSavingsExclDiscount: Savings;
    /** The total savings including discount. */
    totalSavingsInclDiscount: Savings;
};

/** The family unit price. */
export type FamilyUnitPrice = {
    __typename?: 'FamilyUnitPrice';
    /** The price to pay excluding tax. */
    exclTax: Scalars['Float']['output'];
    /** The price to pay including tax. */
    inclTax: Maybe<Scalars['Float']['output']>;
    /** Lowest previous price last 30 days */
    lowestPreviousPrice: Maybe<Price>;
    /** The previous price. */
    previousPrice: Maybe<Price>;
    /** The tax to pay. */
    tax: Maybe<Scalars['Float']['output']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']['output']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']['output']>;
};

/** The fees, e.g. "eco", "weee" */
export type Fees = {
    __typename?: 'Fees';
    /** ECO fee. */
    eco: Maybe<Scalars['Float']['output']>;
    /** Fee for electrical equipment in EU (Waste Electrical and Electronic Equipment). */
    weee: Maybe<Scalars['Float']['output']>;
};

/** The Image details of a product. */
export type Image = {
    __typename?: 'Image';
    /** The filename. */
    filename: Scalars['String']['output'];
    /** The height. */
    height: Scalars['Int']['output'];
    /** The quality. */
    quality: Scalars['String']['output'];
    /** The type. */
    type: Scalars['String']['output'];
    /** The url. */
    url: Scalars['String']['output'];
    /** The width. */
    width: Scalars['Int']['output'];
};

/** The imperial measurement details. */
export type Imperial = {
    __typename?: 'Imperial';
    /** The unit of measurement. */
    unit: Scalars['String']['output'];
    /** The value of measurement. */
    value: Scalars['String']['output'];
};

/**
 * Inspiration is a container of images from the inspiration feed.
 *
 * Note: The inspiration data type is an experimental feature and may have breaking changes without warning or
 * be removed entirely in future releases.
 */
export type Inspiration = {
    __typename?: 'Inspiration';
    /** The inspiration id, e.g. '01094A6D-15AE-4513-BECC4641FDC64991'. */
    id: Scalars['ID']['output'];
    /** Short version of the inspiration id. */
    shortId: Scalars['String']['output'];
    /** Url safe name for the inspiration. */
    slug: Scalars['String']['output'];
    /** The url of the inspiration. */
    url: Scalars['String']['output'];
};

/** Item in a list. */
export type Item = {
    __typename?: 'Item';
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int']['output'];
    /** The articles, can optionally be sorted. */
    articles: Array<Article>;
    /** Availability for purchasing the item */
    availability: Maybe<Availability>;
    /** The business areas the item belongs to. */
    businessStructure: BusinessStructure;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean']['output'];
    /** Is this item buyable online? */
    isBuyableOnline: Scalars['Boolean']['output'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean']['output'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean']['output'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean']['output'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Item prices */
    prices: ItemPricePerSalesChannel;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int']['output'];
    /** Total packages */
    totalPackages: Scalars['Int']['output'];
    /** The item type */
    type: ItemType;
    /** UNIX time in seconds for when the item was updated. */
    updatedAt: Scalars['Int']['output'];
    /** The total weight. */
    weight: Weight;
};

/** Item in a list. */
export type ItemArticlesArgs = {
    orderBy: InputMaybe<ArticleOrder>;
};

/** Input for presentation of item details */
export type ItemPresentation = {
    /** The language code to use for texts */
    languageCode: Scalars['String']['input'];
    /** The id of the store, required for store specific availability and prices */
    storeId: InputMaybe<Scalars['String']['input']>;
    /** The zip (postal) code, when included better home delivery availability can be provided */
    zip: InputMaybe<Scalars['String']['input']>;
};

/** Price for the item */
export type ItemPrice = {
    __typename?: 'ItemPrice';
    /** The family price. */
    family: Maybe<FamilyPrice>;
    /** The fees, e.g. eco or weee */
    fees: Maybe<Fees>;
    /** The regular price */
    regular: Maybe<RegularPrice>;
};

/** The prices for the sales channels */
export type ItemPricePerSalesChannel = {
    __typename?: 'ItemPricePerSalesChannel';
    /** Price in home delivery context. */
    online: Maybe<ItemPrice>;
    /** Price in store context. Requires storeId in itemPresentation input. */
    store: Maybe<ItemPrice>;
};

/** The type of an item. */
export enum ItemType {
    /** ART type. */
    Art = 'ART',
    /** SPR type. */
    Spr = 'SPR',
}

/**
 * A list is a container of items. It is possible to retrieve online and store prices,
 * as well as store availability and location on each item.
 *
 * Note! If you do a mutation and the response type is a List, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type List = {
    __typename?: 'List';
    /** The context. */
    context: Context;
    /** Application designs in the list */
    designs: Array<Design>;
    /** A boolean value that indicate if the list is the currently selected list. */
    isSelected: Scalars['Boolean']['output'];
    /** The items in the list. Requires languageCode in itemPresentation input. */
    items: Array<Item>;
    /** The id. */
    listId: Scalars['ID']['output'];
    /** The name. */
    name: Scalars['String']['output'];
    /** The quantity. */
    quantity: Scalars['Int']['output'];
    /**
     * The retail list. Input languageCode are mandatory to get the list for that specific retail.
     * @deprecated Use itemList instead for better availability structure
     */
    retail: RetailList;
    /**
     * The store list. Input parameters storeId and languageCode are mandatory to get the list for that specific store.
     * @deprecated Use itemList instead for better availability structure
     */
    store: StoreList;
    /** The totals for the items in the list. Requires languageCoded in itemPresentation input. */
    totals: Maybe<Totals>;
    /**
     * The date when the list was last modified. Format is ISO 8601 and the time zone is coordinated universal
     * time (UTC), YYYY-MM-DDThh:mm:ssZ, e.g. "2019-02-06T21:56:00Z".
     */
    updated: Scalars['String']['output'];
};

/**
 * A list is a container of items. It is possible to retrieve online and store prices,
 * as well as store availability and location on each item.
 *
 * Note! If you do a mutation and the response type is a List, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type ListRetailArgs = {
    languageCode: Scalars['String']['input'];
};

/**
 * A list is a container of items. It is possible to retrieve online and store prices,
 * as well as store availability and location on each item.
 *
 * Note! If you do a mutation and the response type is a List, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type ListStoreArgs = {
    languageCode: Scalars['String']['input'];
    storeId: Scalars['String']['input'];
};

/** The store location of an item. */
export type Location = {
    __typename?: 'Location';
    /** The department in the store where the item can be found. */
    department: Maybe<Department>;
    /** The depot location where the item can be found in store. */
    depot: Maybe<Depot>;
};

/** The measurements. */
export type Measurement = {
    __typename?: 'Measurement';
    /** The imperial measurement value. */
    imperial: Maybe<Scalars['String']['output']>;
    /** The metric measurement value. */
    metric: Maybe<Scalars['String']['output']>;
};

/** The metric measurement details. */
export type Metric = {
    __typename?: 'Metric';
    /** The unit of measurement. */
    unit: Scalars['String']['output'];
    /** The value of measurement. */
    value: Scalars['String']['output'];
};

export type MinimalDesign = {
    __typename?: 'MinimalDesign';
    /** The IKEA VPC code, e.g. "NMP827" */
    configurationId: Scalars['ID']['output'];
    /** The quantity of a design */
    quantity: Scalars['Int']['output'];
};

/**
 * MinimalInspiration is a container of inspirations from the inspiration feed.
 *
 * Note! The inspiration data type is an experimental feature and may have breaking changes without warning or
 * be removed entirely in future releases.
 * Built to be used by list-agent for polling the current state.
 */
export type MinimalInspiration = {
    __typename?: 'MinimalInspiration';
    /** The inspiration id, e.g. '01094A6D-15AE-4513-BECC4641FDC64991'. */
    id: Scalars['ID']['output'];
};

export type MinimalItem = {
    __typename?: 'MinimalItem';
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** The quantity of an item. */
    quantity: Scalars['Int']['output'];
};

export type MinimalList = {
    __typename?: 'MinimalList';
    /** The designs in the list. Not filtered in any way, so might contain unavailable designs. */
    designs: Array<MinimalDesign>;
    /** A boolean value that indicate if the list is the currently selected list. */
    isSelected: Scalars['Boolean']['output'];
    /** The items in the list. Not filtered in any way, so might contain unavailable items. */
    items: Array<MinimalItem>;
    /** The id. */
    listId: Scalars['ID']['output'];
    /** The name. */
    name: Scalars['String']['output'];
    /**
     * The date when the list was last modified. Format is ISO 8601 and the time zone is coordinated universal
     * time (UTC), YYYY-MM-DDThh:mm:ssZ, e.g. "2019-02-06T21:56:00Z".
     */
    updated: Scalars['String']['output'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type Mutation = {
    __typename?: 'Mutation';
    /**
     * Adds designs to the list. Until cart will start support quantity >1
     * on individual designs this mutation will always set quantity to 1 no matter what is sent in.
     *
     * --- ERROR CODES ---
     * LIST_NOT_FOUND: The list id was not found.
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * DESIGNS_NOT_FOUND: Designs not found in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     * INTERNAL_ERROR: An internal error has occurred.
     */
    addDesigns: List;
    /**
     * Adds inspirations to the user's inspirational feed.
     *
     * --- ERROR CODES ---
     *
     * INVALID_INSPIRATION_IDS: One or more inspiration ids are not valid or available.
     * An "ids" array is also returned on "extensions.ids" with the faulty ids.
     *
     * INTERNAL_ERROR: An internal error has occurred.
     *
     * MAX_CREATE_LIMIT: The maximum number of images was reached.
     */
    addInspirations: Array<Inspiration>;
    /**
     * Adds items to the list.
     *
     * --- ERROR CODES ---
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_ITEM_NUMBER: Invalid or unavailable item number(s) in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * LIST_NOT_FOUND: The list id was not found.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     * MAX_CREATE_LIMIT: The maximum number of items was reached.
     */
    addItems: List;
    /**
     * Creates a new list.
     *
     * --- ERROR CODES ---
     * EMPTY_NAME: Can not create a list with empty name.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MAX_CREATE_LIMIT: The maximum number of lists was reached.
     * MAX_NAME_LENGTH: The name is too long.
     * NAME_CONFLICT: The name already exists.
     */
    createList: List;
    /**
     * Moves inspirations from a guest user to a logged in user in the same retail id.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * MAX_CREATE_LIMIT: The maximum number of images was reached.
     * PERMISSION_DENIED: Cannot move inspiration. Source user should be a guest user.
     * UNAVAILABLE_INSPIRATIONS_REMOVED: Removed unavailable inspirations.
     */
    moveInspirations: Array<Inspiration>;
    /**
     * Moves lists from a guest user to a logged in user.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MAX_CREATE_LIMIT: The maximum number of lists was reached.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     * PERMISSION_DENIED: Cannot copy items from logged in user to guest user.
     */
    moveLists: Array<List>;
    /**
     * Removes designs from the list.
     *
     * --- ERROR CODES ---
     * LIST_NOT_FOUND: The list id was not found.
     * DESIGNS_NOT_FOUND: Designs not found in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     * INTERNAL_ERROR: An internal error has occurred.
     */
    removeDesigns: List;
    /**
     * Removes inspirations from the user's inspirational feed.
     *
     * Note: The inspiration mutations are experimental feature and may have breaking changes without warning or
     * be removed entirely in future releases.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * UNAVAILABLE_INSPIRATIONS_REMOVED: Removed unavailable inspirations.
     */
    removeInspirations: Array<Inspiration>;
    /**
     * Remove items from the list.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * LIST_NOT_FOUND: The list id was not found.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    removeItems: List;
    /**
     * Removes the list.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * LIST_NOT_FOUND: The list id was not found.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    removeList: Array<List>;
    /**
     * Renames the list.
     *
     * --- ERROR CODES ---
     * EMPTY_NAME: Can not create a list with empty name.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MAX_NAME_LENGTH: The name is too long.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     * NAME_CONFLICT: The name already exists.
     */
    renameList: List;
    /**
     * Sets if an item is collected.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * LIST_NOT_FOUND: The list id was not found.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    setCollected: List;
    /**
     * Sets which list is currently selected.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * LIST_NOT_FOUND: The list id was not found.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    setSelected: Array<List>;
    /**
     * Updates designs in the list.
     *
     * --- ERROR CODES ---
     *   LIST_NOT_FOUND: The list id was not found.
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * DESIGNS_NOT_FOUND: Designs not found in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     * INTERNAL_ERROR: An internal error has occurred.
     */
    updateDesigns: List;
    /**
     * Update items in the list.
     *
     * --- ERROR CODES ---
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_ITEM_NUMBER: Invalid or unavailable item number(s) in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * LIST_NOT_FOUND: The list id was not found.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    updateItems: List;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddDesignsArgs = {
    designs: Array<AddDesignInput>;
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddInspirationsArgs = {
    ids: Array<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddItemsArgs = {
    ignoreUnavailable?: InputMaybe<Scalars['Boolean']['input']>;
    itemPresentation: InputMaybe<ItemPresentation>;
    items: Array<AddItemInput>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationCreateListArgs = {
    name: Scalars['String']['input'];
    setSelected: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationMoveInspirationsArgs = {
    languageCode: Scalars['String']['input'];
    sourceUserId: Scalars['ID']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationMoveListsArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
    sourceUserId: Scalars['ID']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveDesignsArgs = {
    configurationIds: Array<Scalars['ID']['input']>;
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveInspirationsArgs = {
    ids: Array<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveItemsArgs = {
    itemNos: Array<Scalars['ID']['input']>;
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveListArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: Scalars['ID']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRenameListArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: Scalars['ID']['input'];
    name: Scalars['String']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetCollectedArgs = {
    isCollected: Scalars['Boolean']['input'];
    itemNo: Scalars['ID']['input'];
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: InputMaybe<Scalars['ID']['input']>;
    parentItemNo: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetSelectedArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: Scalars['ID']['input'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationUpdateDesignsArgs = {
    designs: Array<UpdateDesignsInput>;
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationUpdateItemsArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
    items: Array<UpdateItemInput>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/** The Package. */
export type Package = {
    __typename?: 'Package';
    /** The id. */
    id: Scalars['Int']['output'];
    /** The measurements. */
    measurements: Array<PackageMeasurement>;
};

/** The package measurements. */
export type PackageMeasurement = {
    __typename?: 'PackageMeasurement';
    /** The imperial text. */
    textImperial: Scalars['String']['output'];
    /** The metric text. */
    textMetric: Scalars['String']['output'];
    /** The type. */
    type: Scalars['String']['output'];
    /** The type name. */
    typeName: Scalars['String']['output'];
    /** The unit for imperial. */
    unitImperial: Scalars['String']['output'];
    /** The unit for metric. */
    unitMetric: Scalars['String']['output'];
    /** The imperial value. */
    valueImperial: Scalars['String']['output'];
    /** The metric value. */
    valueMetric: Scalars['String']['output'];
};

/** The current position based on GPS location. */
export type Position = {
    __typename?: 'Position';
    /** The latitude. */
    lat: Scalars['Float']['output'];
    /** The longitude. */
    lon: Scalars['Float']['output'];
};

/** The necessary input when finding a position. */
export type PositionInput = {
    /** The latitude. */
    lat: Scalars['Float']['input'];
    /** The longitude. */
    lon: Scalars['Float']['input'];
};

/** The price. */
export type Price = {
    __typename?: 'Price';
    /** The price excluding tax. */
    exclTax: Scalars['Float']['output'];
    /** The price including tax. */
    inclTax: Maybe<Scalars['Float']['output']>;
    /** The tax. */
    tax: Maybe<Scalars['Float']['output']>;
    /** A list of tax details. */
    taxDetails: Array<TaxDetail>;
};

/** The product information. */
export type Product = {
    __typename?: 'Product';
    /** The product category */
    category: Maybe<Scalars['String']['output']>;
    /** A short description of the product. */
    description: Maybe<Scalars['String']['output']>;
    /** The package details." */
    displayUnit: Maybe<DisplayUnit>;
    /** The global name. */
    globalName: Scalars['String']['output'];
    /** The Image details. */
    images: Array<Image>;
    /** The measurements. */
    measurements: Array<Measurement>;
    /** The name. */
    name: Scalars['String']['output'];
    /** The technical compliances. */
    technicalCompliances: Array<TechnicalCompliance>;
    /** The technical details. */
    technicalDetails: Maybe<TechnicalDetails>;
    /** The product type */
    type: Scalars['String']['output'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type Query = {
    __typename?: 'Query';
    /**
     * Lookup which context you have.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    context: Context;
    /**
     * Fetch user's inspirations from the inspirational feed.
     *
     * Note: The inspiration queries are experimental feature and may have breaking changes without warning or
     * be removed entirely in future releases.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * UNAVAILABLE_INSPIRATIONS_REMOVED: Removed unavailable inspirations.
     */
    inspirations: Array<Inspiration>;
    /**
     * Lookup a specific list with an id.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    list: List;
    /**
     * Lookup all lists.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * MISSING_ITEM_PRESENTATION: The itemPresentation is required for a queried field (e.g. product description)
     */
    lists: Array<List>;
    /**
     * Fetch user's inspirations from the inspirational feed.
     *
     * Note! Does not query any downstream systems and gets the data only from the database.
     * Built to be used by list-agent for polling the current state.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    minimalInspirations: Array<MinimalInspiration>;
    /**
     * Returns core information regarding all your lists.
     * Does not query any downstream systems.
     * Built to be used by list-agent for polling the current state.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    minimalLists: Array<MinimalList>;
    /**
     * Lookup a specific store with an id.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_STORE_ID: Could not find store id.
     */
    store: Store;
    /**
     * Lookup all stores. Optional position input which will sort the stores from closest to
     * furthest away from that point.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    stores: Array<Store>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryInspirationsArgs = {
    languageCode: Scalars['String']['input'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryListArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
    listId: InputMaybe<Scalars['ID']['input']>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryListsArgs = {
    itemPresentation: InputMaybe<ItemPresentation>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryStoreArgs = {
    languageCode: Scalars['String']['input'];
    storeId: Scalars['String']['input'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryStoresArgs = {
    languageCode: Scalars['String']['input'];
    position: InputMaybe<PositionInput>;
};

/** The regular price details. */
export type RegularPrice = {
    __typename?: 'RegularPrice';
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The unit details. */
    unit: RegularUnitPrice;
};

/** The regular total price. */
export type RegularTotalPrice = {
    __typename?: 'RegularTotalPrice';
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
};

/** The regular unit price. */
export type RegularUnitPrice = {
    __typename?: 'RegularUnitPrice';
    /** The price to pay excluding tax. */
    exclTax: Scalars['Float']['output'];
    /** The price to pay including tax. */
    inclTax: Maybe<Scalars['Float']['output']>;
    /** Is the price a lower price item? */
    isLowerPrice: Scalars['Boolean']['output'];
    /** Lowest previous price last 30 days */
    lowestPreviousPrice: Maybe<Price>;
    /** The previous price. */
    previousPrice: Maybe<Price>;
    /** The tax to pay. */
    tax: Maybe<Scalars['Float']['output']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']['output']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']['output']>;
};

/** Restock data. Used to determine when an item is expected to be back in stock. */
export type Restock = {
    __typename?: 'Restock';
    earliestDate: Scalars['String']['output'];
    latestDate: Scalars['String']['output'];
};

/** The retail article. */
export type RetailArticle = BaseArticle & {
    __typename?: 'RetailArticle';
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int']['output'];
    /** Weight per article. */
    weight: Weight;
};

/** The retail item. */
export type RetailItem = BaseItem & {
    __typename?: 'RetailItem';
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int']['output'];
    /** The article. */
    articles: Array<RetailArticle>;
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean']['output'];
    /** Is this item buyable online? */
    isBuyableOnline: Scalars['Boolean']['output'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean']['output'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean']['output'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean']['output'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int']['output'];
    /** The regular price. */
    regularPrice: Maybe<RegularPrice>;
    /** Total packages */
    totalPackages: Scalars['Int']['output'];
    /** The item type */
    type: ItemType;
    /** The total weight. */
    weight: Weight;
};

/** The retail list, i.e online version of the list. */
export type RetailList = {
    __typename?: 'RetailList';
    /**
     * The currency code for pricing. Based on ISO 4217, which represents currency as a
     * three-letter alphabetic code ("EUR", "SEK").
     */
    currency: Maybe<Scalars['String']['output']>;
    /** The total family price. */
    familyTotalPrice: Maybe<FamilyTotalPrice>;
    /** A list of all items in retail list */
    items: Array<RetailItem>;
    /** The total regular price. */
    regularTotalPrice: Maybe<RegularTotalPrice>;
    /** Total packages. */
    totalPackages: Maybe<Scalars['Int']['output']>;
    /** The total weight. */
    weight: Maybe<Weight>;
};

/** The savings. */
export type Savings = {
    __typename?: 'Savings';
    /** The savings amount. */
    amount: Scalars['Float']['output'];
};

/** The store contains information about the store. */
export type Store = {
    __typename?: 'Store';
    /** The store address. */
    address: StoreAddress;
    /** The name. */
    name: Scalars['String']['output'];
    /** The current position based on GPS location. */
    position: Position;
    /** The store id. */
    storeId: Scalars['String']['output'];
    /** The time zone id based on the area and location, e.g Europe/London. */
    timeZone: Scalars['String']['output'];
};

/** The store address. */
export type StoreAddress = {
    __typename?: 'StoreAddress';
    /** The address. */
    address: Scalars['String']['output'];
    /** The city. */
    city: Scalars['String']['output'];
    /** The zip code. */
    zipCode: Scalars['String']['output'];
};

/** The store article. */
export type StoreArticle = BaseArticle & {
    __typename?: 'StoreArticle';
    /** Is item collected? */
    isCollected: Scalars['Boolean']['output'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Storage location. */
    location: Maybe<Location>;
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int']['output'];
    /** Weight per article. */
    weight: Weight;
};

/** StoreArticle sorting options */
export enum StoreArticleOrderOption {
    /** Sort articles by itemNo */
    Itemno = 'ITEMNO',
    /**
     * Sorts by location, moving articles with department to the front
     * of the list and then two stage sort on bin and aisle.
     */
    Location = 'LOCATION',
}

/** The Store item. */
export type StoreItem = BaseItem & {
    __typename?: 'StoreItem';
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int']['output'];
    /** Articles. */
    articles: Array<StoreArticle>;
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean']['output'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean']['output'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean']['output'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean']['output'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['output'];
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int']['output'];
    /** The regular price. */
    regularPrice: Maybe<RegularPrice>;
    /** Restocks data, a.k.a estimated back in stock. */
    restocks: Array<Restock>;
    /** Stock information. */
    stock: Maybe<Scalars['Int']['output']>;
    /** Total packages */
    totalPackages: Scalars['Int']['output'];
    /** The item type */
    type: ItemType;
    /** The total weight. */
    weight: Weight;
};

/** The Store item. */
export type StoreItemArticlesArgs = {
    orderBy: InputMaybe<StoreArticleOrderOption>;
};

/** The store list, i.e a specific store view of the list. */
export type StoreList = {
    __typename?: 'StoreList';
    /**
     * The currency code for pricing. Based on ISO 4217, which represents currency as a
     * three-letter alphabetic code ("EUR", "SEK").
     */
    currency: Maybe<Scalars['String']['output']>;
    /** The family total price. */
    familyTotalPrice: Maybe<FamilyTotalPrice>;
    /** A list of all items in store list */
    items: Array<StoreItem>;
    /** The regular total price. */
    regularTotalPrice: Maybe<RegularTotalPrice>;
    /** The store id, e.g 012 for Stockholm Kungens kurva IKEA store */
    storeId: Scalars['String']['output'];
    /** Total packages. */
    totalPackages: Maybe<Scalars['Int']['output']>;
    /** The total weight. */
    weight: Maybe<Weight>;
};

/** The tax details. */
export type TaxDetail = {
    __typename?: 'TaxDetail';
    /** The tax amount. */
    amount: Scalars['Float']['output'];
    /** The tax rate. */
    rate: Scalars['Float']['output'];
    /** The taxable amount. */
    taxableAmount: Scalars['Float']['output'];
    /** The tax type. */
    type: Scalars['String']['output'];
};

/** The technical compliance. */
export type TechnicalCompliance = {
    __typename?: 'TechnicalCompliance';
    /** The technical compliance title. */
    title: Maybe<Scalars['String']['output']>;
    /** The technical compliance type. */
    type: Maybe<Scalars['String']['output']>;
    /** The technical compliance value. */
    value: Maybe<Scalars['String']['output']>;
};

/** The technical details. */
export type TechnicalDetails = {
    __typename?: 'TechnicalDetails';
    /** Image PDF url */
    imagePdfURL: Scalars['String']['output'];
    /** Image url */
    imageUrl: Scalars['String']['output'];
    /** Label PDF url */
    labelPdfUrl: Scalars['String']['output'];
    /** Label url */
    labelUrl: Scalars['String']['output'];
};

/** Total price for items */
export type TotalPrice = {
    __typename?: 'TotalPrice';
    /**
     * The currency code for pricing. Based on ISO 4217, which represents currency as a
     * three-letter alphabetic code ("EUR", "SEK").
     */
    currency: Scalars['String']['output'];
    /** The family total price. */
    family: Maybe<FamilyTotalPrice>;
    /** The regular total price */
    regular: Maybe<RegularTotalPrice>;
};

/** The price for buying options */
export type TotalPricePerSalesChannel = {
    __typename?: 'TotalPricePerSalesChannel';
    /** Total price calculated in home delivery context. */
    online: Maybe<TotalPrice>;
    /** Total price calculated in store context. Requires storeId in itemPresentation input. */
    store: Maybe<TotalPrice>;
};

/** Total weight type. */
export type TotalWeight = {
    __typename?: 'TotalWeight';
    /** The imperial weight. */
    imperial: Scalars['String']['output'];
    /** The metric weight. */
    metric: Scalars['String']['output'];
};

/** Sum of item totals. */
export type Totals = {
    __typename?: 'Totals';
    /** Total number of packages. */
    packages: Maybe<Scalars['Int']['output']>;
    /** Price information */
    prices: Maybe<TotalPricePerSalesChannel>;
    /** Total weight. */
    weight: Maybe<Weight>;
};

/** The unit type. */
export enum UnitType {
    /** The area type. */
    Area = 'AREA',
    /** The meterware type. */
    Meterware = 'METERWARE',
    /** The multipack type. */
    Multipack = 'MULTIPACK',
    /** The piece type. */
    Piece = 'PIECE',
    /** The volume type. */
    Volume = 'VOLUME',
    /** The weight type. */
    Weight = 'WEIGHT',
    /** The weightware type. */
    Weightware = 'WEIGHTWARE',
}

/** The necessary input when updating items. */
export type UpdateDesignsInput = {
    /** The IKEA item number, e.g. "00263850" */
    configurationId: Scalars['ID']['input'];
    /** The quantity of items. */
    quantity: Scalars['Int']['input'];
};

/** The necessary input when updating items. */
export type UpdateItemInput = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID']['input'];
    /** The quantity of items. */
    quantity: Scalars['Int']['input'];
};

/** Weight type. */
export type Weight = {
    __typename?: 'Weight';
    /** The imperial weight. */
    imperial: Maybe<Imperial>;
    /** The metric weight. */
    metric: Maybe<Metric>;
};

/** The weight per item type. */
export type WeightPerArticle = {
    __typename?: 'WeightPerArticle';
    /** The imperial weight. */
    imperial: Scalars['String']['output'];
    /** The metric weight. */
    metric: Scalars['String']['output'];
};

export type ArticleFragment = {
    __typename?: 'Article';
    itemNo: string;
    quantityPerItem: number;
    product: {
        __typename?: 'Product';
        globalName: string;
        name: string;
        type: string;
        description: string | null;
        measurements: Array<{
            __typename?: 'Measurement';
            imperial: string | null;
            metric: string | null;
        }>;
        images: Array<{ __typename?: 'Image'; quality: string; url: string }>;
        displayUnit: {
            __typename?: 'DisplayUnit';
            imperial: {
                __typename?: 'Imperial';
                value: string;
                unit: string;
            } | null;
            metric: {
                __typename?: 'Metric';
                value: string;
                unit: string;
            } | null;
        } | null;
        technicalCompliances: Array<{
            __typename?: 'TechnicalCompliance';
            title: string | null;
            type: string | null;
            value: string | null;
        }>;
    } | null;
    location?: {
        __typename?: 'Location';
        depot: { __typename?: 'Depot'; aisle: string; bin: string } | null;
        department: { __typename?: 'Department'; name: string } | null;
    } | null;
    packages: Array<{
        __typename?: 'Package';
        id: number;
        measurements: Array<{
            __typename?: 'PackageMeasurement';
            textImperial: string;
            textMetric: string;
            type: string;
            typeName: string;
            unitImperial: string;
            unitMetric: string;
            valueImperial: string;
            valueMetric: string;
        }>;
    }>;
};

export type DesignFragment = {
    __typename?: 'Design';
    application: string;
    configurationId: string;
    quantity: number;
    images: Array<{
        __typename?: 'DesignImage';
        typeCode: string;
        url: string;
    }>;
    items: Array<{
        __typename?: 'Item';
        itemNo: string;
        quantity: number;
        addedAt: number;
        type: ItemType;
        isBreathTakingItem: boolean;
        isFamilyItem: boolean;
        isNew: boolean;
        isBuyableOnline: boolean;
        isCommunicatedOnline: boolean;
        totalPackages: number;
        product: {
            __typename?: 'Product';
            globalName: string;
            name: string;
            type: string;
            description: string | null;
            measurements: Array<{
                __typename?: 'Measurement';
                imperial: string | null;
                metric: string | null;
            }>;
            images: Array<{
                __typename?: 'Image';
                quality: string;
                url: string;
            }>;
            displayUnit: {
                __typename?: 'DisplayUnit';
                imperial: {
                    __typename?: 'Imperial';
                    value: string;
                    unit: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    value: string;
                    unit: string;
                } | null;
            } | null;
            technicalCompliances: Array<{
                __typename?: 'TechnicalCompliance';
                title: string | null;
                type: string | null;
                value: string | null;
            }>;
        } | null;
        articles: Array<{
            __typename?: 'Article';
            itemNo: string;
            quantityPerItem: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            location?: {
                __typename?: 'Location';
                depot: {
                    __typename?: 'Depot';
                    aisle: string;
                    bin: string;
                } | null;
                department: { __typename?: 'Department'; name: string } | null;
            } | null;
            packages: Array<{
                __typename?: 'Package';
                id: number;
                measurements: Array<{
                    __typename?: 'PackageMeasurement';
                    textImperial: string;
                    textMetric: string;
                    type: string;
                    typeName: string;
                    unitImperial: string;
                    unitMetric: string;
                    valueImperial: string;
                    valueMetric: string;
                }>;
            }>;
        }>;
        weight: {
            __typename?: 'Weight';
            imperial: {
                __typename?: 'Imperial';
                unit: string;
                value: string;
            } | null;
            metric: {
                __typename?: 'Metric';
                unit: string;
                value: string;
            } | null;
        };
        prices: {
            __typename?: 'ItemPricePerSalesChannel';
            store?: {
                __typename?: 'ItemPrice';
                fees: {
                    __typename?: 'Fees';
                    weee: number | null;
                    eco: number | null;
                } | null;
                family: {
                    __typename?: 'FamilyPrice';
                    unit: {
                        __typename?: 'FamilyUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
                regular: {
                    __typename?: 'RegularPrice';
                    unit: {
                        __typename?: 'RegularUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        isLowerPrice: boolean;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
            } | null;
            online: {
                __typename?: 'ItemPrice';
                fees: {
                    __typename?: 'Fees';
                    weee: number | null;
                    eco: number | null;
                } | null;
                family: {
                    __typename?: 'FamilyPrice';
                    unit: {
                        __typename?: 'FamilyUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
                regular: {
                    __typename?: 'RegularPrice';
                    unit: {
                        __typename?: 'RegularUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        isLowerPrice: boolean;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
            } | null;
        };
        availability: {
            __typename?: 'Availability';
            cashCarry?: {
                __typename?: 'BuyingOption';
                available: boolean;
                messageType: string | null;
                quantity: number | null;
                restocks: Array<{
                    __typename?: 'Restock';
                    earliestDate: string;
                    latestDate: string;
                }>;
            } | null;
            clickCollect?: {
                __typename?: 'BuyingOption';
                available: boolean;
                messageType: string | null;
                quantity: number | null;
                restocks: Array<{
                    __typename?: 'Restock';
                    earliestDate: string;
                    latestDate: string;
                }>;
            } | null;
            homeDelivery: {
                __typename?: 'BuyingOption';
                available: boolean;
                messageType: string | null;
                quantity: number | null;
                restocks: Array<{
                    __typename?: 'Restock';
                    earliestDate: string;
                    latestDate: string;
                }>;
            } | null;
        } | null;
    }>;
    links: {
        __typename?: 'DesignLinks';
        deepLink: string;
        shortUrl: string;
    } | null;
    prices: {
        __typename?: 'DesignPricePerSalesChannel';
        online: {
            __typename?: 'DesignPrice';
            regular: {
                __typename?: 'RegularPrice';
                unit: {
                    __typename?: 'RegularUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    isLowerPrice: boolean;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
            family: {
                __typename?: 'FamilyPrice';
                unit: {
                    __typename?: 'FamilyUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
        } | null;
        store?: {
            __typename?: 'DesignPrice';
            regular: {
                __typename?: 'RegularPrice';
                unit: {
                    __typename?: 'RegularUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    isLowerPrice: boolean;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
            family: {
                __typename?: 'FamilyPrice';
                unit: {
                    __typename?: 'FamilyUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
        } | null;
    };
};

export type EmptyListFragment = {
    __typename?: 'List';
    updated: string;
    quantity: number;
    name: string;
    isSelected: boolean;
    listId: string;
};

export type FamilyItemPriceFragment = {
    __typename?: 'FamilyPrice';
    unit: {
        __typename?: 'FamilyUnitPrice';
        exclTax: number;
        inclTax: number | null;
        tax: number | null;
        validFrom: string | null;
        validTo: string | null;
        lowestPreviousPrice: {
            __typename?: 'Price';
            exclTax: number;
            inclTax: number | null;
        } | null;
        previousPrice: {
            __typename?: 'Price';
            exclTax: number;
            inclTax: number | null;
        } | null;
    };
    subTotalInclDiscount: {
        __typename?: 'Price';
        exclTax: number;
        inclTax: number | null;
    };
};

export type FamilyTotalPriceFragment = {
    __typename?: 'FamilyTotalPrice';
    totalDiscount: { __typename?: 'Savings'; amount: number };
    totalExclDiscount: {
        __typename?: 'Price';
        tax: number | null;
        inclTax: number | null;
        exclTax: number;
    };
    totalInclDiscount: {
        __typename?: 'Price';
        tax: number | null;
        inclTax: number | null;
        exclTax: number;
    };
    totalSavingsExclDiscount: { __typename?: 'Savings'; amount: number };
    totalSavingsInclDiscount: { __typename?: 'Savings'; amount: number };
};

export type ItemFragment = {
    __typename?: 'Item';
    itemNo: string;
    quantity: number;
    addedAt: number;
    type: ItemType;
    isBreathTakingItem: boolean;
    isFamilyItem: boolean;
    isNew: boolean;
    isBuyableOnline: boolean;
    isCommunicatedOnline: boolean;
    totalPackages: number;
    product: {
        __typename?: 'Product';
        globalName: string;
        name: string;
        type: string;
        description: string | null;
        measurements: Array<{
            __typename?: 'Measurement';
            imperial: string | null;
            metric: string | null;
        }>;
        images: Array<{ __typename?: 'Image'; quality: string; url: string }>;
        displayUnit: {
            __typename?: 'DisplayUnit';
            imperial: {
                __typename?: 'Imperial';
                value: string;
                unit: string;
            } | null;
            metric: {
                __typename?: 'Metric';
                value: string;
                unit: string;
            } | null;
        } | null;
        technicalCompliances: Array<{
            __typename?: 'TechnicalCompliance';
            title: string | null;
            type: string | null;
            value: string | null;
        }>;
    } | null;
    articles: Array<{
        __typename?: 'Article';
        itemNo: string;
        quantityPerItem: number;
        product: {
            __typename?: 'Product';
            globalName: string;
            name: string;
            type: string;
            description: string | null;
            measurements: Array<{
                __typename?: 'Measurement';
                imperial: string | null;
                metric: string | null;
            }>;
            images: Array<{
                __typename?: 'Image';
                quality: string;
                url: string;
            }>;
            displayUnit: {
                __typename?: 'DisplayUnit';
                imperial: {
                    __typename?: 'Imperial';
                    value: string;
                    unit: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    value: string;
                    unit: string;
                } | null;
            } | null;
            technicalCompliances: Array<{
                __typename?: 'TechnicalCompliance';
                title: string | null;
                type: string | null;
                value: string | null;
            }>;
        } | null;
        location?: {
            __typename?: 'Location';
            depot: { __typename?: 'Depot'; aisle: string; bin: string } | null;
            department: { __typename?: 'Department'; name: string } | null;
        } | null;
        packages: Array<{
            __typename?: 'Package';
            id: number;
            measurements: Array<{
                __typename?: 'PackageMeasurement';
                textImperial: string;
                textMetric: string;
                type: string;
                typeName: string;
                unitImperial: string;
                unitMetric: string;
                valueImperial: string;
                valueMetric: string;
            }>;
        }>;
    }>;
    weight: {
        __typename?: 'Weight';
        imperial: {
            __typename?: 'Imperial';
            unit: string;
            value: string;
        } | null;
        metric: { __typename?: 'Metric'; unit: string; value: string } | null;
    };
    prices: {
        __typename?: 'ItemPricePerSalesChannel';
        store?: {
            __typename?: 'ItemPrice';
            fees: {
                __typename?: 'Fees';
                weee: number | null;
                eco: number | null;
            } | null;
            family: {
                __typename?: 'FamilyPrice';
                unit: {
                    __typename?: 'FamilyUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
            regular: {
                __typename?: 'RegularPrice';
                unit: {
                    __typename?: 'RegularUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    isLowerPrice: boolean;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
        } | null;
        online: {
            __typename?: 'ItemPrice';
            fees: {
                __typename?: 'Fees';
                weee: number | null;
                eco: number | null;
            } | null;
            family: {
                __typename?: 'FamilyPrice';
                unit: {
                    __typename?: 'FamilyUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
            regular: {
                __typename?: 'RegularPrice';
                unit: {
                    __typename?: 'RegularUnitPrice';
                    exclTax: number;
                    inclTax: number | null;
                    isLowerPrice: boolean;
                    tax: number | null;
                    validFrom: string | null;
                    validTo: string | null;
                    lowestPreviousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                    previousPrice: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    } | null;
                };
                subTotalInclDiscount: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                };
            } | null;
        } | null;
    };
    availability: {
        __typename?: 'Availability';
        cashCarry?: {
            __typename?: 'BuyingOption';
            available: boolean;
            messageType: string | null;
            quantity: number | null;
            restocks: Array<{
                __typename?: 'Restock';
                earliestDate: string;
                latestDate: string;
            }>;
        } | null;
        clickCollect?: {
            __typename?: 'BuyingOption';
            available: boolean;
            messageType: string | null;
            quantity: number | null;
            restocks: Array<{
                __typename?: 'Restock';
                earliestDate: string;
                latestDate: string;
            }>;
        } | null;
        homeDelivery: {
            __typename?: 'BuyingOption';
            available: boolean;
            messageType: string | null;
            quantity: number | null;
            restocks: Array<{
                __typename?: 'Restock';
                earliestDate: string;
                latestDate: string;
            }>;
        } | null;
    } | null;
};

export type ItemAvailabilityFragment = {
    __typename?: 'Availability';
    cashCarry?: {
        __typename?: 'BuyingOption';
        available: boolean;
        messageType: string | null;
        quantity: number | null;
        restocks: Array<{
            __typename?: 'Restock';
            earliestDate: string;
            latestDate: string;
        }>;
    } | null;
    clickCollect?: {
        __typename?: 'BuyingOption';
        available: boolean;
        messageType: string | null;
        quantity: number | null;
        restocks: Array<{
            __typename?: 'Restock';
            earliestDate: string;
            latestDate: string;
        }>;
    } | null;
    homeDelivery: {
        __typename?: 'BuyingOption';
        available: boolean;
        messageType: string | null;
        quantity: number | null;
        restocks: Array<{
            __typename?: 'Restock';
            earliestDate: string;
            latestDate: string;
        }>;
    } | null;
};

export type ItemPricesFragment = {
    __typename?: 'ItemPricePerSalesChannel';
    store?: {
        __typename?: 'ItemPrice';
        fees: {
            __typename?: 'Fees';
            weee: number | null;
            eco: number | null;
        } | null;
        family: {
            __typename?: 'FamilyPrice';
            unit: {
                __typename?: 'FamilyUnitPrice';
                exclTax: number;
                inclTax: number | null;
                tax: number | null;
                validFrom: string | null;
                validTo: string | null;
                lowestPreviousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
                previousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
            };
            subTotalInclDiscount: {
                __typename?: 'Price';
                exclTax: number;
                inclTax: number | null;
            };
        } | null;
        regular: {
            __typename?: 'RegularPrice';
            unit: {
                __typename?: 'RegularUnitPrice';
                exclTax: number;
                inclTax: number | null;
                isLowerPrice: boolean;
                tax: number | null;
                validFrom: string | null;
                validTo: string | null;
                lowestPreviousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
                previousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
            };
            subTotalInclDiscount: {
                __typename?: 'Price';
                exclTax: number;
                inclTax: number | null;
            };
        } | null;
    } | null;
    online: {
        __typename?: 'ItemPrice';
        fees: {
            __typename?: 'Fees';
            weee: number | null;
            eco: number | null;
        } | null;
        family: {
            __typename?: 'FamilyPrice';
            unit: {
                __typename?: 'FamilyUnitPrice';
                exclTax: number;
                inclTax: number | null;
                tax: number | null;
                validFrom: string | null;
                validTo: string | null;
                lowestPreviousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
                previousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
            };
            subTotalInclDiscount: {
                __typename?: 'Price';
                exclTax: number;
                inclTax: number | null;
            };
        } | null;
        regular: {
            __typename?: 'RegularPrice';
            unit: {
                __typename?: 'RegularUnitPrice';
                exclTax: number;
                inclTax: number | null;
                isLowerPrice: boolean;
                tax: number | null;
                validFrom: string | null;
                validTo: string | null;
                lowestPreviousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
                previousPrice: {
                    __typename?: 'Price';
                    exclTax: number;
                    inclTax: number | null;
                } | null;
            };
            subTotalInclDiscount: {
                __typename?: 'Price';
                exclTax: number;
                inclTax: number | null;
            };
        } | null;
    } | null;
};

export type ListFragment = {
    __typename?: 'List';
    updated: string;
    quantity: number;
    name: string;
    isSelected: boolean;
    listId: string;
    totals: {
        __typename?: 'Totals';
        weight: {
            __typename?: 'Weight';
            imperial: {
                __typename?: 'Imperial';
                unit: string;
                value: string;
            } | null;
            metric: {
                __typename?: 'Metric';
                unit: string;
                value: string;
            } | null;
        } | null;
        prices: {
            __typename?: 'TotalPricePerSalesChannel';
            store?: {
                __typename?: 'TotalPrice';
                currency: string;
                family: {
                    __typename?: 'FamilyTotalPrice';
                    totalDiscount: { __typename?: 'Savings'; amount: number };
                    totalExclDiscount: {
                        __typename?: 'Price';
                        tax: number | null;
                        inclTax: number | null;
                        exclTax: number;
                    };
                    totalInclDiscount: {
                        __typename?: 'Price';
                        tax: number | null;
                        inclTax: number | null;
                        exclTax: number;
                    };
                    totalSavingsExclDiscount: {
                        __typename?: 'Savings';
                        amount: number;
                    };
                    totalSavingsInclDiscount: {
                        __typename?: 'Savings';
                        amount: number;
                    };
                } | null;
                regular: {
                    __typename?: 'RegularTotalPrice';
                    totalDiscount: { __typename?: 'Savings'; amount: number };
                    totalExclDiscount: {
                        __typename?: 'Price';
                        inclTax: number | null;
                        exclTax: number;
                        tax: number | null;
                        taxDetails: Array<{
                            __typename?: 'TaxDetail';
                            type: string;
                            rate: number;
                            amount: number;
                            taxableAmount: number;
                        }>;
                    };
                    totalInclDiscount: {
                        __typename?: 'Price';
                        inclTax: number | null;
                        exclTax: number;
                        tax: number | null;
                        taxDetails: Array<{
                            __typename?: 'TaxDetail';
                            type: string;
                            rate: number;
                            amount: number;
                            taxableAmount: number;
                        }>;
                    };
                } | null;
            } | null;
            online: {
                __typename?: 'TotalPrice';
                currency: string;
                family: {
                    __typename?: 'FamilyTotalPrice';
                    totalDiscount: { __typename?: 'Savings'; amount: number };
                    totalExclDiscount: {
                        __typename?: 'Price';
                        tax: number | null;
                        inclTax: number | null;
                        exclTax: number;
                    };
                    totalInclDiscount: {
                        __typename?: 'Price';
                        tax: number | null;
                        inclTax: number | null;
                        exclTax: number;
                    };
                    totalSavingsExclDiscount: {
                        __typename?: 'Savings';
                        amount: number;
                    };
                    totalSavingsInclDiscount: {
                        __typename?: 'Savings';
                        amount: number;
                    };
                } | null;
                regular: {
                    __typename?: 'RegularTotalPrice';
                    totalDiscount: { __typename?: 'Savings'; amount: number };
                    totalExclDiscount: {
                        __typename?: 'Price';
                        inclTax: number | null;
                        exclTax: number;
                        tax: number | null;
                        taxDetails: Array<{
                            __typename?: 'TaxDetail';
                            type: string;
                            rate: number;
                            amount: number;
                            taxableAmount: number;
                        }>;
                    };
                    totalInclDiscount: {
                        __typename?: 'Price';
                        inclTax: number | null;
                        exclTax: number;
                        tax: number | null;
                        taxDetails: Array<{
                            __typename?: 'TaxDetail';
                            type: string;
                            rate: number;
                            amount: number;
                            taxableAmount: number;
                        }>;
                    };
                } | null;
            } | null;
        } | null;
    } | null;
    items: Array<{
        __typename?: 'Item';
        itemNo: string;
        quantity: number;
        addedAt: number;
        type: ItemType;
        isBreathTakingItem: boolean;
        isFamilyItem: boolean;
        isNew: boolean;
        isBuyableOnline: boolean;
        isCommunicatedOnline: boolean;
        totalPackages: number;
        product: {
            __typename?: 'Product';
            globalName: string;
            name: string;
            type: string;
            description: string | null;
            measurements: Array<{
                __typename?: 'Measurement';
                imperial: string | null;
                metric: string | null;
            }>;
            images: Array<{
                __typename?: 'Image';
                quality: string;
                url: string;
            }>;
            displayUnit: {
                __typename?: 'DisplayUnit';
                imperial: {
                    __typename?: 'Imperial';
                    value: string;
                    unit: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    value: string;
                    unit: string;
                } | null;
            } | null;
            technicalCompliances: Array<{
                __typename?: 'TechnicalCompliance';
                title: string | null;
                type: string | null;
                value: string | null;
            }>;
        } | null;
        articles: Array<{
            __typename?: 'Article';
            itemNo: string;
            quantityPerItem: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            location?: {
                __typename?: 'Location';
                depot: {
                    __typename?: 'Depot';
                    aisle: string;
                    bin: string;
                } | null;
                department: { __typename?: 'Department'; name: string } | null;
            } | null;
            packages: Array<{
                __typename?: 'Package';
                id: number;
                measurements: Array<{
                    __typename?: 'PackageMeasurement';
                    textImperial: string;
                    textMetric: string;
                    type: string;
                    typeName: string;
                    unitImperial: string;
                    unitMetric: string;
                    valueImperial: string;
                    valueMetric: string;
                }>;
            }>;
        }>;
        weight: {
            __typename?: 'Weight';
            imperial: {
                __typename?: 'Imperial';
                unit: string;
                value: string;
            } | null;
            metric: {
                __typename?: 'Metric';
                unit: string;
                value: string;
            } | null;
        };
        prices: {
            __typename?: 'ItemPricePerSalesChannel';
            store?: {
                __typename?: 'ItemPrice';
                fees: {
                    __typename?: 'Fees';
                    weee: number | null;
                    eco: number | null;
                } | null;
                family: {
                    __typename?: 'FamilyPrice';
                    unit: {
                        __typename?: 'FamilyUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
                regular: {
                    __typename?: 'RegularPrice';
                    unit: {
                        __typename?: 'RegularUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        isLowerPrice: boolean;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
            } | null;
            online: {
                __typename?: 'ItemPrice';
                fees: {
                    __typename?: 'Fees';
                    weee: number | null;
                    eco: number | null;
                } | null;
                family: {
                    __typename?: 'FamilyPrice';
                    unit: {
                        __typename?: 'FamilyUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
                regular: {
                    __typename?: 'RegularPrice';
                    unit: {
                        __typename?: 'RegularUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        isLowerPrice: boolean;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
            } | null;
        };
        availability: {
            __typename?: 'Availability';
            cashCarry?: {
                __typename?: 'BuyingOption';
                available: boolean;
                messageType: string | null;
                quantity: number | null;
                restocks: Array<{
                    __typename?: 'Restock';
                    earliestDate: string;
                    latestDate: string;
                }>;
            } | null;
            clickCollect?: {
                __typename?: 'BuyingOption';
                available: boolean;
                messageType: string | null;
                quantity: number | null;
                restocks: Array<{
                    __typename?: 'Restock';
                    earliestDate: string;
                    latestDate: string;
                }>;
            } | null;
            homeDelivery: {
                __typename?: 'BuyingOption';
                available: boolean;
                messageType: string | null;
                quantity: number | null;
                restocks: Array<{
                    __typename?: 'Restock';
                    earliestDate: string;
                    latestDate: string;
                }>;
            } | null;
        } | null;
    }>;
    designs: Array<{
        __typename?: 'Design';
        application: string;
        configurationId: string;
        quantity: number;
        images: Array<{
            __typename?: 'DesignImage';
            typeCode: string;
            url: string;
        }>;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        links: {
            __typename?: 'DesignLinks';
            deepLink: string;
            shortUrl: string;
        } | null;
        prices: {
            __typename?: 'DesignPricePerSalesChannel';
            online: {
                __typename?: 'DesignPrice';
                regular: {
                    __typename?: 'RegularPrice';
                    unit: {
                        __typename?: 'RegularUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        isLowerPrice: boolean;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
                family: {
                    __typename?: 'FamilyPrice';
                    unit: {
                        __typename?: 'FamilyUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
            } | null;
            store?: {
                __typename?: 'DesignPrice';
                regular: {
                    __typename?: 'RegularPrice';
                    unit: {
                        __typename?: 'RegularUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        isLowerPrice: boolean;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
                family: {
                    __typename?: 'FamilyPrice';
                    unit: {
                        __typename?: 'FamilyUnitPrice';
                        exclTax: number;
                        inclTax: number | null;
                        tax: number | null;
                        validFrom: string | null;
                        validTo: string | null;
                        lowestPreviousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                        previousPrice: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        } | null;
                    };
                    subTotalInclDiscount: {
                        __typename?: 'Price';
                        exclTax: number;
                        inclTax: number | null;
                    };
                } | null;
            } | null;
        };
    }>;
};

export type ProductFragment = {
    __typename?: 'Product';
    globalName: string;
    name: string;
    type: string;
    description: string | null;
    measurements: Array<{
        __typename?: 'Measurement';
        imperial: string | null;
        metric: string | null;
    }>;
    images: Array<{ __typename?: 'Image'; quality: string; url: string }>;
    displayUnit: {
        __typename?: 'DisplayUnit';
        imperial: {
            __typename?: 'Imperial';
            value: string;
            unit: string;
        } | null;
        metric: { __typename?: 'Metric'; value: string; unit: string } | null;
    } | null;
    technicalCompliances: Array<{
        __typename?: 'TechnicalCompliance';
        title: string | null;
        type: string | null;
        value: string | null;
    }>;
};

export type RegularItemPriceFragment = {
    __typename?: 'RegularPrice';
    unit: {
        __typename?: 'RegularUnitPrice';
        exclTax: number;
        inclTax: number | null;
        isLowerPrice: boolean;
        tax: number | null;
        validFrom: string | null;
        validTo: string | null;
        lowestPreviousPrice: {
            __typename?: 'Price';
            exclTax: number;
            inclTax: number | null;
        } | null;
        previousPrice: {
            __typename?: 'Price';
            exclTax: number;
            inclTax: number | null;
        } | null;
    };
    subTotalInclDiscount: {
        __typename?: 'Price';
        exclTax: number;
        inclTax: number | null;
    };
};

export type RegularTotalPriceFragment = {
    __typename?: 'RegularTotalPrice';
    totalDiscount: { __typename?: 'Savings'; amount: number };
    totalExclDiscount: {
        __typename?: 'Price';
        inclTax: number | null;
        exclTax: number;
        tax: number | null;
        taxDetails: Array<{
            __typename?: 'TaxDetail';
            type: string;
            rate: number;
            amount: number;
            taxableAmount: number;
        }>;
    };
    totalInclDiscount: {
        __typename?: 'Price';
        inclTax: number | null;
        exclTax: number;
        tax: number | null;
        taxDetails: Array<{
            __typename?: 'TaxDetail';
            type: string;
            rate: number;
            amount: number;
            taxableAmount: number;
        }>;
    };
};

export type TotalsFragment = {
    __typename?: 'Totals';
    weight: {
        __typename?: 'Weight';
        imperial: {
            __typename?: 'Imperial';
            unit: string;
            value: string;
        } | null;
        metric: { __typename?: 'Metric'; unit: string; value: string } | null;
    } | null;
    prices: {
        __typename?: 'TotalPricePerSalesChannel';
        store?: {
            __typename?: 'TotalPrice';
            currency: string;
            family: {
                __typename?: 'FamilyTotalPrice';
                totalDiscount: { __typename?: 'Savings'; amount: number };
                totalExclDiscount: {
                    __typename?: 'Price';
                    tax: number | null;
                    inclTax: number | null;
                    exclTax: number;
                };
                totalInclDiscount: {
                    __typename?: 'Price';
                    tax: number | null;
                    inclTax: number | null;
                    exclTax: number;
                };
                totalSavingsExclDiscount: {
                    __typename?: 'Savings';
                    amount: number;
                };
                totalSavingsInclDiscount: {
                    __typename?: 'Savings';
                    amount: number;
                };
            } | null;
            regular: {
                __typename?: 'RegularTotalPrice';
                totalDiscount: { __typename?: 'Savings'; amount: number };
                totalExclDiscount: {
                    __typename?: 'Price';
                    inclTax: number | null;
                    exclTax: number;
                    tax: number | null;
                    taxDetails: Array<{
                        __typename?: 'TaxDetail';
                        type: string;
                        rate: number;
                        amount: number;
                        taxableAmount: number;
                    }>;
                };
                totalInclDiscount: {
                    __typename?: 'Price';
                    inclTax: number | null;
                    exclTax: number;
                    tax: number | null;
                    taxDetails: Array<{
                        __typename?: 'TaxDetail';
                        type: string;
                        rate: number;
                        amount: number;
                        taxableAmount: number;
                    }>;
                };
            } | null;
        } | null;
        online: {
            __typename?: 'TotalPrice';
            currency: string;
            family: {
                __typename?: 'FamilyTotalPrice';
                totalDiscount: { __typename?: 'Savings'; amount: number };
                totalExclDiscount: {
                    __typename?: 'Price';
                    tax: number | null;
                    inclTax: number | null;
                    exclTax: number;
                };
                totalInclDiscount: {
                    __typename?: 'Price';
                    tax: number | null;
                    inclTax: number | null;
                    exclTax: number;
                };
                totalSavingsExclDiscount: {
                    __typename?: 'Savings';
                    amount: number;
                };
                totalSavingsInclDiscount: {
                    __typename?: 'Savings';
                    amount: number;
                };
            } | null;
            regular: {
                __typename?: 'RegularTotalPrice';
                totalDiscount: { __typename?: 'Savings'; amount: number };
                totalExclDiscount: {
                    __typename?: 'Price';
                    inclTax: number | null;
                    exclTax: number;
                    tax: number | null;
                    taxDetails: Array<{
                        __typename?: 'TaxDetail';
                        type: string;
                        rate: number;
                        amount: number;
                        taxableAmount: number;
                    }>;
                };
                totalInclDiscount: {
                    __typename?: 'Price';
                    inclTax: number | null;
                    exclTax: number;
                    tax: number | null;
                    taxDetails: Array<{
                        __typename?: 'TaxDetail';
                        type: string;
                        rate: number;
                        amount: number;
                        taxableAmount: number;
                    }>;
                };
            } | null;
        } | null;
    } | null;
};

export type WeightFragment = {
    __typename?: 'Weight';
    imperial: { __typename?: 'Imperial'; unit: string; value: string } | null;
    metric: { __typename?: 'Metric'; unit: string; value: string } | null;
};

export type AddDesignsMutationVariables = Exact<{
    designs: Array<AddDesignInput> | AddDesignInput;
    listId: InputMaybe<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
}>;

export type AddDesignsMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type AddItemsMutationVariables = Exact<{
    items: Array<AddItemInput> | AddItemInput;
    listId: InputMaybe<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    ignoreUnavailable: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type AddItemsMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type CreateListMutationVariables = Exact<{
    name: Scalars['String']['input'];
}>;

export type CreateListMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
    };
};

export type MoveListsMutationVariables = Exact<{
    sourceUserId: Scalars['ID']['input'];
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type MoveListsMutation = {
    __typename?: 'Mutation';
    lists: Array<{
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    }>;
};

export type RemoveDesignsMutationVariables = Exact<{
    configurationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
    listId: InputMaybe<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
}>;

export type RemoveDesignsMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type RemoveItemsMutationVariables = Exact<{
    itemNos: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
    listId: InputMaybe<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveItemsMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type RemoveListMutationVariables = Exact<{
    listId: Scalars['ID']['input'];
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveListMutation = {
    __typename?: 'Mutation';
    lists: Array<{
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    }>;
};

export type RenameListMutationVariables = Exact<{
    listId: Scalars['ID']['input'];
    name: Scalars['String']['input'];
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type RenameListMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type SetSelectedMutationVariables = Exact<{
    listId: Scalars['ID']['input'];
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type SetSelectedMutation = {
    __typename?: 'Mutation';
    lists: Array<{
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    }>;
};

export type UpdateDesignsMutationVariables = Exact<{
    designs: Array<UpdateDesignsInput> | UpdateDesignsInput;
    listId: InputMaybe<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateDesignsMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type UpdateItemsMutationVariables = Exact<{
    items: Array<UpdateItemInput> | UpdateItemInput;
    languageCode: Scalars['String']['input'];
    listId: InputMaybe<Scalars['ID']['input']>;
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateItemsMutation = {
    __typename?: 'Mutation';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type GetListQueryVariables = Exact<{
    listId: InputMaybe<Scalars['ID']['input']>;
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type GetListQuery = {
    __typename?: 'Query';
    list: {
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    };
};

export type GetListsQueryVariables = Exact<{
    languageCode: Scalars['String']['input'];
    storeId: InputMaybe<Scalars['String']['input']>;
    withStore: Scalars['Boolean']['input'];
    zip: InputMaybe<Scalars['String']['input']>;
}>;

export type GetListsQuery = {
    __typename?: 'Query';
    lists: Array<{
        __typename?: 'List';
        updated: string;
        quantity: number;
        name: string;
        isSelected: boolean;
        listId: string;
        totals: {
            __typename?: 'Totals';
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            } | null;
            prices: {
                __typename?: 'TotalPricePerSalesChannel';
                store?: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'TotalPrice';
                    currency: string;
                    family: {
                        __typename?: 'FamilyTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            tax: number | null;
                            inclTax: number | null;
                            exclTax: number;
                        };
                        totalSavingsExclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalSavingsInclDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularTotalPrice';
                        totalDiscount: {
                            __typename?: 'Savings';
                            amount: number;
                        };
                        totalExclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                        totalInclDiscount: {
                            __typename?: 'Price';
                            inclTax: number | null;
                            exclTax: number;
                            tax: number | null;
                            taxDetails: Array<{
                                __typename?: 'TaxDetail';
                                type: string;
                                rate: number;
                                amount: number;
                                taxableAmount: number;
                            }>;
                        };
                    } | null;
                } | null;
            } | null;
        } | null;
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            addedAt: number;
            type: ItemType;
            isBreathTakingItem: boolean;
            isFamilyItem: boolean;
            isNew: boolean;
            isBuyableOnline: boolean;
            isCommunicatedOnline: boolean;
            totalPackages: number;
            product: {
                __typename?: 'Product';
                globalName: string;
                name: string;
                type: string;
                description: string | null;
                measurements: Array<{
                    __typename?: 'Measurement';
                    imperial: string | null;
                    metric: string | null;
                }>;
                images: Array<{
                    __typename?: 'Image';
                    quality: string;
                    url: string;
                }>;
                displayUnit: {
                    __typename?: 'DisplayUnit';
                    imperial: {
                        __typename?: 'Imperial';
                        value: string;
                        unit: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        value: string;
                        unit: string;
                    } | null;
                } | null;
                technicalCompliances: Array<{
                    __typename?: 'TechnicalCompliance';
                    title: string | null;
                    type: string | null;
                    value: string | null;
                }>;
            } | null;
            articles: Array<{
                __typename?: 'Article';
                itemNo: string;
                quantityPerItem: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                location?: {
                    __typename?: 'Location';
                    depot: {
                        __typename?: 'Depot';
                        aisle: string;
                        bin: string;
                    } | null;
                    department: {
                        __typename?: 'Department';
                        name: string;
                    } | null;
                } | null;
                packages: Array<{
                    __typename?: 'Package';
                    id: number;
                    measurements: Array<{
                        __typename?: 'PackageMeasurement';
                        textImperial: string;
                        textMetric: string;
                        type: string;
                        typeName: string;
                        unitImperial: string;
                        unitMetric: string;
                        valueImperial: string;
                        valueMetric: string;
                    }>;
                }>;
            }>;
            weight: {
                __typename?: 'Weight';
                imperial: {
                    __typename?: 'Imperial';
                    unit: string;
                    value: string;
                } | null;
                metric: {
                    __typename?: 'Metric';
                    unit: string;
                    value: string;
                } | null;
            };
            prices: {
                __typename?: 'ItemPricePerSalesChannel';
                store?: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                online: {
                    __typename?: 'ItemPrice';
                    fees: {
                        __typename?: 'Fees';
                        weee: number | null;
                        eco: number | null;
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
            availability: {
                __typename?: 'Availability';
                cashCarry?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                clickCollect?: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
                homeDelivery: {
                    __typename?: 'BuyingOption';
                    available: boolean;
                    messageType: string | null;
                    quantity: number | null;
                    restocks: Array<{
                        __typename?: 'Restock';
                        earliestDate: string;
                        latestDate: string;
                    }>;
                } | null;
            } | null;
        }>;
        designs: Array<{
            __typename?: 'Design';
            application: string;
            configurationId: string;
            quantity: number;
            images: Array<{
                __typename?: 'DesignImage';
                typeCode: string;
                url: string;
            }>;
            items: Array<{
                __typename?: 'Item';
                itemNo: string;
                quantity: number;
                addedAt: number;
                type: ItemType;
                isBreathTakingItem: boolean;
                isFamilyItem: boolean;
                isNew: boolean;
                isBuyableOnline: boolean;
                isCommunicatedOnline: boolean;
                totalPackages: number;
                product: {
                    __typename?: 'Product';
                    globalName: string;
                    name: string;
                    type: string;
                    description: string | null;
                    measurements: Array<{
                        __typename?: 'Measurement';
                        imperial: string | null;
                        metric: string | null;
                    }>;
                    images: Array<{
                        __typename?: 'Image';
                        quality: string;
                        url: string;
                    }>;
                    displayUnit: {
                        __typename?: 'DisplayUnit';
                        imperial: {
                            __typename?: 'Imperial';
                            value: string;
                            unit: string;
                        } | null;
                        metric: {
                            __typename?: 'Metric';
                            value: string;
                            unit: string;
                        } | null;
                    } | null;
                    technicalCompliances: Array<{
                        __typename?: 'TechnicalCompliance';
                        title: string | null;
                        type: string | null;
                        value: string | null;
                    }>;
                } | null;
                articles: Array<{
                    __typename?: 'Article';
                    itemNo: string;
                    quantityPerItem: number;
                    product: {
                        __typename?: 'Product';
                        globalName: string;
                        name: string;
                        type: string;
                        description: string | null;
                        measurements: Array<{
                            __typename?: 'Measurement';
                            imperial: string | null;
                            metric: string | null;
                        }>;
                        images: Array<{
                            __typename?: 'Image';
                            quality: string;
                            url: string;
                        }>;
                        displayUnit: {
                            __typename?: 'DisplayUnit';
                            imperial: {
                                __typename?: 'Imperial';
                                value: string;
                                unit: string;
                            } | null;
                            metric: {
                                __typename?: 'Metric';
                                value: string;
                                unit: string;
                            } | null;
                        } | null;
                        technicalCompliances: Array<{
                            __typename?: 'TechnicalCompliance';
                            title: string | null;
                            type: string | null;
                            value: string | null;
                        }>;
                    } | null;
                    location?: {
                        __typename?: 'Location';
                        depot: {
                            __typename?: 'Depot';
                            aisle: string;
                            bin: string;
                        } | null;
                        department: {
                            __typename?: 'Department';
                            name: string;
                        } | null;
                    } | null;
                    packages: Array<{
                        __typename?: 'Package';
                        id: number;
                        measurements: Array<{
                            __typename?: 'PackageMeasurement';
                            textImperial: string;
                            textMetric: string;
                            type: string;
                            typeName: string;
                            unitImperial: string;
                            unitMetric: string;
                            valueImperial: string;
                            valueMetric: string;
                        }>;
                    }>;
                }>;
                weight: {
                    __typename?: 'Weight';
                    imperial: {
                        __typename?: 'Imperial';
                        unit: string;
                        value: string;
                    } | null;
                    metric: {
                        __typename?: 'Metric';
                        unit: string;
                        value: string;
                    } | null;
                };
                prices: {
                    __typename?: 'ItemPricePerSalesChannel';
                    store?: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                    online: {
                        __typename?: 'ItemPrice';
                        fees: {
                            __typename?: 'Fees';
                            weee: number | null;
                            eco: number | null;
                        } | null;
                        family: {
                            __typename?: 'FamilyPrice';
                            unit: {
                                __typename?: 'FamilyUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                        regular: {
                            __typename?: 'RegularPrice';
                            unit: {
                                __typename?: 'RegularUnitPrice';
                                exclTax: number;
                                inclTax: number | null;
                                isLowerPrice: boolean;
                                tax: number | null;
                                validFrom: string | null;
                                validTo: string | null;
                                lowestPreviousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                                previousPrice: {
                                    __typename?: 'Price';
                                    exclTax: number;
                                    inclTax: number | null;
                                } | null;
                            };
                            subTotalInclDiscount: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            };
                        } | null;
                    } | null;
                };
                availability: {
                    __typename?: 'Availability';
                    cashCarry?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    clickCollect?: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                    homeDelivery: {
                        __typename?: 'BuyingOption';
                        available: boolean;
                        messageType: string | null;
                        quantity: number | null;
                        restocks: Array<{
                            __typename?: 'Restock';
                            earliestDate: string;
                            latestDate: string;
                        }>;
                    } | null;
                } | null;
            }>;
            links: {
                __typename?: 'DesignLinks';
                deepLink: string;
                shortUrl: string;
            } | null;
            prices: {
                __typename?: 'DesignPricePerSalesChannel';
                online: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
                store?: {
                    __typename?: 'DesignPrice';
                    regular: {
                        __typename?: 'RegularPrice';
                        unit: {
                            __typename?: 'RegularUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            isLowerPrice: boolean;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                    family: {
                        __typename?: 'FamilyPrice';
                        unit: {
                            __typename?: 'FamilyUnitPrice';
                            exclTax: number;
                            inclTax: number | null;
                            tax: number | null;
                            validFrom: string | null;
                            validTo: string | null;
                            lowestPreviousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                            previousPrice: {
                                __typename?: 'Price';
                                exclTax: number;
                                inclTax: number | null;
                            } | null;
                        };
                        subTotalInclDiscount: {
                            __typename?: 'Price';
                            exclTax: number;
                            inclTax: number | null;
                        };
                    } | null;
                } | null;
            };
        }>;
    }>;
};

export const EmptyListFragmentDoc = `
    fragment EmptyList on List {
  updated
  quantity
  name
  isSelected
  listId
}
    `;
export const WeightFragmentDoc = `
    fragment Weight on Weight {
  imperial {
    unit
    value
  }
  metric {
    unit
    value
  }
}
    `;
export const FamilyTotalPriceFragmentDoc = `
    fragment FamilyTotalPrice on FamilyTotalPrice {
  totalDiscount {
    amount
  }
  totalExclDiscount {
    tax
    inclTax
    exclTax
  }
  totalInclDiscount {
    tax
    inclTax
    exclTax
  }
  totalSavingsExclDiscount {
    amount
  }
  totalSavingsInclDiscount {
    amount
  }
}
    `;
export const RegularTotalPriceFragmentDoc = `
    fragment RegularTotalPrice on RegularTotalPrice {
  totalDiscount {
    amount
  }
  totalExclDiscount {
    inclTax
    exclTax
    tax
    taxDetails {
      type
      rate
      amount
      taxableAmount
    }
  }
  totalInclDiscount {
    inclTax
    exclTax
    tax
    taxDetails {
      type
      rate
      amount
      taxableAmount
    }
  }
}
    `;
export const TotalsFragmentDoc = `
    fragment Totals on Totals {
  weight {
    ...Weight
  }
  prices {
    store @include(if: $withStore) {
      currency
      family {
        ...FamilyTotalPrice
      }
      regular {
        ...RegularTotalPrice
      }
    }
    online {
      currency
      family {
        ...FamilyTotalPrice
      }
      regular {
        ...RegularTotalPrice
      }
    }
  }
}
    `;
export const ProductFragmentDoc = `
    fragment Product on Product {
  globalName
  name
  type
  description
  measurements {
    imperial
    metric
  }
  images {
    quality
    url
  }
  displayUnit {
    imperial {
      value
      unit
    }
    metric {
      value
      unit
    }
  }
  technicalCompliances {
    title
    type
    value
  }
}
    `;
export const ArticleFragmentDoc = `
    fragment Article on Article {
  itemNo
  quantityPerItem
  product {
    ...Product
  }
  location @include(if: $withStore) {
    depot {
      aisle
      bin
    }
    department {
      name
    }
  }
  packages {
    id
    measurements {
      textImperial
      textMetric
      type
      typeName
      unitImperial
      unitMetric
      valueImperial
      valueMetric
    }
  }
}
    `;
export const FamilyItemPriceFragmentDoc = `
    fragment FamilyItemPrice on FamilyPrice {
  unit {
    exclTax
    inclTax
    lowestPreviousPrice {
      exclTax
      inclTax
    }
    previousPrice {
      exclTax
      inclTax
    }
    tax
    validFrom
    validTo
  }
  subTotalInclDiscount {
    exclTax
    inclTax
  }
}
    `;
export const RegularItemPriceFragmentDoc = `
    fragment RegularItemPrice on RegularPrice {
  unit {
    exclTax
    inclTax
    isLowerPrice
    lowestPreviousPrice {
      exclTax
      inclTax
    }
    previousPrice {
      exclTax
      inclTax
    }
    tax
    validFrom
    validTo
  }
  subTotalInclDiscount {
    exclTax
    inclTax
  }
}
    `;
export const ItemPricesFragmentDoc = `
    fragment ItemPrices on ItemPricePerSalesChannel {
  store @include(if: $withStore) {
    fees {
      weee
      eco
    }
    family {
      ...FamilyItemPrice
    }
    regular {
      ...RegularItemPrice
    }
  }
  online {
    fees {
      weee
      eco
    }
    family {
      ...FamilyItemPrice
    }
    regular {
      ...RegularItemPrice
    }
  }
}
    `;
export const ItemAvailabilityFragmentDoc = `
    fragment ItemAvailability on Availability {
  cashCarry @include(if: $withStore) {
    available
    messageType
    quantity
    restocks {
      earliestDate
      latestDate
    }
  }
  clickCollect @include(if: $withStore) {
    available
    messageType
    quantity
    restocks {
      earliestDate
      latestDate
    }
  }
  homeDelivery {
    available
    messageType
    quantity
    restocks {
      earliestDate
      latestDate
    }
  }
}
    `;
export const ItemFragmentDoc = `
    fragment Item on Item {
  itemNo
  quantity
  addedAt
  type
  isBreathTakingItem
  isFamilyItem
  isNew
  isBuyableOnline
  isCommunicatedOnline
  totalPackages
  product {
    ...Product
  }
  articles {
    ...Article
  }
  weight {
    ...Weight
  }
  prices {
    ...ItemPrices
  }
  availability {
    ...ItemAvailability
  }
}
    `;
export const DesignFragmentDoc = `
    fragment Design on Design {
  application
  configurationId
  quantity
  images {
    typeCode
    url
  }
  items {
    ...Item
  }
  links {
    deepLink
    shortUrl
  }
  prices {
    online {
      regular {
        ...RegularItemPrice
      }
      family {
        ...FamilyItemPrice
      }
    }
    store @include(if: $withStore) {
      regular {
        ...RegularItemPrice
      }
      family {
        ...FamilyItemPrice
      }
    }
  }
}
    `;
export const ListFragmentDoc = `
    fragment List on List {
  updated
  quantity
  name
  isSelected
  listId
  totals {
    ...Totals
  }
  items {
    ...Item
  }
  designs {
    ...Design
  }
}
    `;
export const AddDesignsDocument = `
    mutation AddDesigns($designs: [AddDesignInput!]!, $listId: ID, $languageCode: String!, $storeId: String, $withStore: Boolean!) {
  list: addDesigns(
    designs: $designs
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useAddDesignsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AddDesignsMutation,
        TError,
        AddDesignsMutationVariables,
        TContext
    >
) =>
    useMutation<
        AddDesignsMutation,
        TError,
        AddDesignsMutationVariables,
        TContext
    >(
        ['AddDesigns'],
        useFetchData<AddDesignsMutation, AddDesignsMutationVariables>(
            AddDesignsDocument
        ),
        options
    );
export const AddItemsDocument = `
    mutation AddItems($items: [AddItemInput!]!, $listId: ID, $languageCode: String!, $storeId: String, $withStore: Boolean!, $ignoreUnavailable: Boolean!, $zip: String) {
  list: addItems(
    items: $items
    listId: $listId
    ignoreUnavailable: $ignoreUnavailable
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useAddItemsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AddItemsMutation,
        TError,
        AddItemsMutationVariables,
        TContext
    >
) =>
    useMutation<AddItemsMutation, TError, AddItemsMutationVariables, TContext>(
        ['AddItems'],
        useFetchData<AddItemsMutation, AddItemsMutationVariables>(
            AddItemsDocument
        ),
        options
    );
export const CreateListDocument = `
    mutation CreateList($name: String!) {
  list: createList(name: $name) {
    ...EmptyList
  }
}
    ${EmptyListFragmentDoc}`;
export const useCreateListMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateListMutation,
        TError,
        CreateListMutationVariables,
        TContext
    >
) =>
    useMutation<
        CreateListMutation,
        TError,
        CreateListMutationVariables,
        TContext
    >(
        ['CreateList'],
        useFetchData<CreateListMutation, CreateListMutationVariables>(
            CreateListDocument
        ),
        options
    );
export const MoveListsDocument = `
    mutation MoveLists($sourceUserId: ID!, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  lists: moveLists(
    sourceUserId: $sourceUserId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useMoveListsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        MoveListsMutation,
        TError,
        MoveListsMutationVariables,
        TContext
    >
) =>
    useMutation<
        MoveListsMutation,
        TError,
        MoveListsMutationVariables,
        TContext
    >(
        ['MoveLists'],
        useFetchData<MoveListsMutation, MoveListsMutationVariables>(
            MoveListsDocument
        ),
        options
    );
export const RemoveDesignsDocument = `
    mutation RemoveDesigns($configurationIds: [ID!]!, $listId: ID, $languageCode: String!, $storeId: String, $withStore: Boolean!) {
  list: removeDesigns(
    configurationIds: $configurationIds
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useRemoveDesignsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RemoveDesignsMutation,
        TError,
        RemoveDesignsMutationVariables,
        TContext
    >
) =>
    useMutation<
        RemoveDesignsMutation,
        TError,
        RemoveDesignsMutationVariables,
        TContext
    >(
        ['RemoveDesigns'],
        useFetchData<RemoveDesignsMutation, RemoveDesignsMutationVariables>(
            RemoveDesignsDocument
        ),
        options
    );
export const RemoveItemsDocument = `
    mutation RemoveItems($itemNos: [ID!]!, $listId: ID, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  list: removeItems(
    itemNos: $itemNos
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useRemoveItemsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RemoveItemsMutation,
        TError,
        RemoveItemsMutationVariables,
        TContext
    >
) =>
    useMutation<
        RemoveItemsMutation,
        TError,
        RemoveItemsMutationVariables,
        TContext
    >(
        ['RemoveItems'],
        useFetchData<RemoveItemsMutation, RemoveItemsMutationVariables>(
            RemoveItemsDocument
        ),
        options
    );
export const RemoveListDocument = `
    mutation RemoveList($listId: ID!, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  lists: removeList(
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useRemoveListMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RemoveListMutation,
        TError,
        RemoveListMutationVariables,
        TContext
    >
) =>
    useMutation<
        RemoveListMutation,
        TError,
        RemoveListMutationVariables,
        TContext
    >(
        ['RemoveList'],
        useFetchData<RemoveListMutation, RemoveListMutationVariables>(
            RemoveListDocument
        ),
        options
    );
export const RenameListDocument = `
    mutation RenameList($listId: ID!, $name: String!, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  list: renameList(
    listId: $listId
    name: $name
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useRenameListMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RenameListMutation,
        TError,
        RenameListMutationVariables,
        TContext
    >
) =>
    useMutation<
        RenameListMutation,
        TError,
        RenameListMutationVariables,
        TContext
    >(
        ['RenameList'],
        useFetchData<RenameListMutation, RenameListMutationVariables>(
            RenameListDocument
        ),
        options
    );
export const SetSelectedDocument = `
    mutation SetSelected($listId: ID!, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  lists: setSelected(
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useSetSelectedMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SetSelectedMutation,
        TError,
        SetSelectedMutationVariables,
        TContext
    >
) =>
    useMutation<
        SetSelectedMutation,
        TError,
        SetSelectedMutationVariables,
        TContext
    >(
        ['SetSelected'],
        useFetchData<SetSelectedMutation, SetSelectedMutationVariables>(
            SetSelectedDocument
        ),
        options
    );
export const UpdateDesignsDocument = `
    mutation UpdateDesigns($designs: [UpdateDesignsInput!]!, $listId: ID, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  list: updateDesigns(
    designs: $designs
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useUpdateDesignsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateDesignsMutation,
        TError,
        UpdateDesignsMutationVariables,
        TContext
    >
) =>
    useMutation<
        UpdateDesignsMutation,
        TError,
        UpdateDesignsMutationVariables,
        TContext
    >(
        ['UpdateDesigns'],
        useFetchData<UpdateDesignsMutation, UpdateDesignsMutationVariables>(
            UpdateDesignsDocument
        ),
        options
    );
export const UpdateItemsDocument = `
    mutation UpdateItems($items: [UpdateItemInput!]!, $languageCode: String!, $listId: ID, $storeId: String, $withStore: Boolean!, $zip: String) {
  list: updateItems(
    items: $items
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useUpdateItemsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateItemsMutation,
        TError,
        UpdateItemsMutationVariables,
        TContext
    >
) =>
    useMutation<
        UpdateItemsMutation,
        TError,
        UpdateItemsMutationVariables,
        TContext
    >(
        ['UpdateItems'],
        useFetchData<UpdateItemsMutation, UpdateItemsMutationVariables>(
            UpdateItemsDocument
        ),
        options
    );
export const GetListDocument = `
    query GetList($listId: ID, $languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  list(
    listId: $listId
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useGetListQuery = <TData = GetListQuery, TError = unknown>(
    variables: GetListQueryVariables,
    options?: UseQueryOptions<GetListQuery, TError, TData>
) =>
    useQuery<GetListQuery, TError, TData>(
        ['GetList', variables],
        useFetchData<GetListQuery, GetListQueryVariables>(GetListDocument).bind(
            null,
            variables
        ),
        options
    );
export const GetListsDocument = `
    query GetLists($languageCode: String!, $storeId: String, $withStore: Boolean!, $zip: String) {
  lists(
    itemPresentation: {languageCode: $languageCode, storeId: $storeId, zip: $zip}
  ) {
    ...List
  }
}
    ${ListFragmentDoc}
${TotalsFragmentDoc}
${WeightFragmentDoc}
${FamilyTotalPriceFragmentDoc}
${RegularTotalPriceFragmentDoc}
${ItemFragmentDoc}
${ProductFragmentDoc}
${ArticleFragmentDoc}
${ItemPricesFragmentDoc}
${FamilyItemPriceFragmentDoc}
${RegularItemPriceFragmentDoc}
${ItemAvailabilityFragmentDoc}
${DesignFragmentDoc}`;
export const useGetListsQuery = <TData = GetListsQuery, TError = unknown>(
    variables: GetListsQueryVariables,
    options?: UseQueryOptions<GetListsQuery, TError, TData>
) =>
    useQuery<GetListsQuery, TError, TData>(
        ['GetLists', variables],
        useFetchData<GetListsQuery, GetListsQueryVariables>(
            GetListsDocument
        ).bind(null, variables),
        options
    );
