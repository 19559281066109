// All defaults
// Market defaults
import { cteDefaults } from './cte-defaults';
import { defaults } from './defaults';
import { localhostDefaults } from './localhost-defaults';
import { ppeDefaults } from './ppe-defaults';
import { prCteDefaults } from './pr-cte-defaults';
import { prPpeDefaults } from './pr-ppe-defaults';
import { SettingsObject } from '@hooks/useSettings';
import { MarketName } from 'lib/global';

const defaultsByEnv = (
    market: MarketName
): Record<string, Partial<SettingsObject>> => {
    return {
        ppe: ppeDefaults,
        cte: cteDefaults(market),
        'pr-ppe': prPpeDefaults,
        'pr-cte': prCteDefaults(market),
        localhost: localhostDefaults,
    };
};

export const envDefaults = (market: MarketName) => {
    const baseDefaults = defaults(market);
    const envDefaults =
        defaultsByEnv(market)[process.env.NEXT_PUBLIC_IKEA_ENV ?? 'localhost'];
    return {
        ...baseDefaults,
        ...envDefaults,
    };
};
