import { useEffect } from 'react';

import { useAnalytics } from './useAnalytics';

export const useLogPrint = () => {
    const { logEvent } = useAnalytics();

    useEffect(() => {
        let mediaQueryList: MediaQueryList | undefined;
        const onBeforePrint = () => logEvent('ecommerce', 'print_wishlist', {});
        const onAfterPrint = () => undefined;
        const mediaQueryListListener = (mql: MediaQueryListEvent) => {
            if (mql.matches) {
                onBeforePrint();
            } else {
                onAfterPrint();
            }
        };

        if (window.matchMedia) {
            mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(mediaQueryListListener);
        } else {
            window.addEventListener('beforeprint', onBeforePrint);
            window.addEventListener('afterprint', onAfterPrint);
        }

        return () => {
            window.removeEventListener('beforeprint', onBeforePrint);
            window.removeEventListener('afterprint', onAfterPrint);
            mediaQueryList?.removeListener(mediaQueryListListener);
        };
    }, [logEvent]);
};
