import { createContext, PropsWithChildren, useContext } from 'react';

import { MarketName } from 'lib/global';

const MarketNameContext = createContext<MarketName>('usen');

export const MarketNameProvider = ({
    children,
    marketName,
}: PropsWithChildren<{ marketName: MarketName }>) => {
    return (
        <MarketNameContext.Provider value={marketName}>
            {children}
        </MarketNameContext.Provider>
    );
};

export const useMarketNameContext = () => useContext(MarketNameContext);
