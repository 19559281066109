import { SettingsObject } from '@hooks/useSettings';

export const ppeDefaults: Partial<SettingsObject> = {
    baseUrl: 'https://www.ppe.ikeadt.com',
    listClientId: 'fe88ae57-2c34-4b14-8979-b915872886f7',
    listEndpoint: 'https://favs.oneweb.ingka.com/graphql',
    optimizelyBase: 'https://www.ppe.ikeadt.com',
    optimizelyKey: 'CwVNQ9rGUcVFbEJYcfZzv',
    optimizelyKeySecondary: 'GRmjFwNPRsL2xKS93o7jt',
    showDevModal: true,
    sunEndpoint: 'https://api.ingka.ppe.ikeadt.com/sun/signup',
    websiteBase: 'https://www.ppe.ikeadt.com',
};
