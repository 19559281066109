import {
    defaultErrorsTranslationCodeMap,
    translationCodeMap,
} from './translationCodeMap';
import { ErrorCategory } from './types';
import { PhraseTranslationKeys } from '@utils/get-message';

const DEFAULT_ERROR_CODE = 'UNKNOWN_ERROR';

export class CustomError extends Error {
    code: string;
    constructor(
        source: ErrorCategory,
        message = '',
        code = DEFAULT_ERROR_CODE
    ) {
        super(message);
        this.name = source;
        this.code = code;
    }

    get translationCode(): PhraseTranslationKeys {
        const translation = translationCodeMap[this.name as ErrorCategory];
        if (this.code in translation) {
            return translation[this.code];
        }
        return defaultErrorsTranslationCodeMap[this.name as ErrorCategory];
    }
}
