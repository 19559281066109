import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useOptimizely } from '@utils/optimizely/useOptimizely';

const EVENT_LISTENER_PRINTING = 'isPrinting';
const EVENT_LISTENER_AFTERPRINT = 'afterprint';

type PrintContextType = {
    isPrinting: boolean;
    print: () => void;
};

const PrintContext = createContext<PrintContextType>({
    isPrinting: false,
    print: () => undefined,
});

export const PrintContextProvider = ({ children }: PropsWithChildren) => {
    const [isPrinting, setIsPrinting] = useState(false);
    const { trackEvent } = useOptimizely();

    useEffect(() => {
        const handlePrinting = () => setIsPrinting(true);
        const handleAfterPrint = () => setIsPrinting(false);

        addEventListener('beforeprint', handlePrinting);
        addEventListener(EVENT_LISTENER_PRINTING, handlePrinting);
        addEventListener(EVENT_LISTENER_AFTERPRINT, handleAfterPrint);

        return () => {
            removeEventListener('beforeprint', handlePrinting);
            removeEventListener(EVENT_LISTENER_PRINTING, handlePrinting);
            removeEventListener(EVENT_LISTENER_AFTERPRINT, handleAfterPrint);
        };
    }, []);

    const print = useCallback(() => {
        trackEvent('favourites_print'); // Optimizely guardrail event
        dispatchEvent(new CustomEvent(EVENT_LISTENER_PRINTING));
        setTimeout(() => {
            window.print();
        }, 500);
    }, [trackEvent]);

    const value = useMemo(
        () => ({
            print,
            isPrinting,
        }),
        [print, isPrinting]
    );

    return (
        <PrintContext.Provider value={value}>{children}</PrintContext.Provider>
    );
};

const usePrint = () => useContext(PrintContext);

export default usePrint;
