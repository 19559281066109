export const clear = () => {
    if (typeof window !== 'undefined' && window.ikea?.store?.clear) {
        window.ikea.store.clear();
    }
};
export const get = () => {
    if (typeof window !== 'undefined' && window.ikea?.store?.get) {
        return window.ikea.store.get();
    }
    return Promise.reject();
};

export const set = (storeId: string) => {
    if (typeof window !== 'undefined' && window.ikea?.store?.set) {
        return window.ikea.store.set(storeId);
    }
    return Promise.reject();
};

export const openStorePicker = (): void => {
    window.ikea.pubsub?.publish('NAVIGATION/OPEN_STORE_PICKER', null);
};
