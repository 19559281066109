import { useCallback, useMemo } from 'react';

import { captureException } from '@sentry/react';

import useMoveLists from 'apis/list/hooks/useMoveLists';

import getUserIdFromToken from 'utils/get-userid-from-token';

type Token = {
    value: string;
    expirationTime: number;
};

const useMoveUser = () => {
    const { moveLists } = useMoveLists();

    const moveListsToLoggedInUser = useCallback(
        (sourceUserId: string) => {
            moveLists(
                { sourceUserId },
                {
                    onError: error => {
                        captureException(
                            new Error(
                                `Error when trying to move lists to logged in user. ${error}`
                            )
                        );
                    },
                }
            );
        },
        [moveLists]
    );

    // Only run this if the conditions apply!
    const moveFromGuestToLoggedInUser = useCallback(
        (guestToken: Token) => {
            const sourceUserId = getUserIdFromToken(guestToken.value);
            moveListsToLoggedInUser(sourceUserId);
        },
        [moveListsToLoggedInUser]
    );

    const useMoveUser = useMemo(
        () => ({ moveFromGuestToLoggedInUser }),
        [moveFromGuestToLoggedInUser]
    );
    return useMoveUser;
};

export default useMoveUser;
