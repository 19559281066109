import { ReactElement } from 'react';

import { PhraseKey } from 'lib/global';

export type Plural = {
    one: string | null;
    other: string | null;
    zero: string | null;
};

export type PhraseValue = null | string | undefined | Plural;

export type PluralisationAmount = 'one' | 'other' | 'zero';

export type Translations = typeof import('phrase/en-XZ.json');

export function isPlural(phraseValue?: PhraseValue): phraseValue is Plural {
    return typeof phraseValue === 'object';
}

export interface ITranslate {
    (
        phraseKey: PhraseKey,
        args?: Record<string, string>,
        pluralisationAmount?: PluralisationAmount
    ): string;
    (
        phraseKey: PhraseKey,
        args?: Record<string, string | ReactElement>,
        pluralisationAmount?: PluralisationAmount
    ): string | ReactElement;
}
