export const isObject = (arg: unknown): arg is object => {
    if (typeof arg !== 'object') {
        return false;
    }
    if (arg === null) {
        return false;
    }
    if (Array.isArray(arg)) {
        return false;
    }
    return true;
};
