import { FavouritesEvents } from '../eventTypes/favourites';

/* 
    This is how the payload was originally defined.
    Since we haven't been allowed to whitelist all of these properties, 
    we are remapping the property names to those of already whitelisted one's.
*/
type EventPayload = {
    items_total: number;
    lists_total: number;
    itemNos: string[];
    list_value: {
        total: number;
        currencyCode: string;
    };
    distance_to_store: number;
    has_store_intent: boolean;
    timestamp: string;
};

type RemappedCustomPayload = ReturnType<
    FavouritesEvents['store_intent']
>['custom'];

const propertyMap: Record<keyof EventPayload, keyof RemappedCustomPayload> = {
    items_total: 'item_number_event',
    lists_total: 'product_list_variant',
    itemNos: 'item_index',
    list_value: 'list_value',
    distance_to_store: 'local_store',
    has_store_intent: 'algorithm',
    timestamp: 'users_local_time',
};

const remapProperties = (
    originalPayload: EventPayload
): RemappedCustomPayload =>
    Object.entries(originalPayload).reduce<RemappedCustomPayload>(
        (acc, [key, value]) => ({
            ...acc,
            [propertyMap[key as keyof typeof propertyMap] || key]: value,
        }),
        {} as RemappedCustomPayload
    );

export const createStoreIntentPayload: FavouritesEvents['store_intent'] = ({
    lists,
    hasStoreIntent,
    stores,
}) => {
    const payload = lists.reduce<
        Pick<EventPayload, 'itemNos' | 'items_total' | 'list_value'>
    >(
        (acc, list) => ({
            ...acc,
            items_total: acc.items_total + list.quantity,
            itemNos: [...acc.itemNos, ...list.items.map(item => item.itemNo)],
            list_value: {
                currencyCode:
                    list.totals?.prices?.online?.currency ||
                    acc.list_value.currencyCode,
                total:
                    acc.list_value.total +
                    (list.totals?.prices?.online?.family?.totalInclDiscount
                        .inclTax || 0),
            },
        }),
        {
            items_total: 0,
            itemNos: [],
            list_value: {
                total: 0,
                currencyCode: '',
            },
        }
    );
    return {
        custom: remapProperties({
            ...payload,
            lists_total: lists.length,
            itemNos: [...new Set(payload.itemNos)], // Remove duplicates
            has_store_intent: hasStoreIntent,
            distance_to_store:
                stores.length > 0 ? Math.min(...stores.map(s => s.dist)) : -1,
            timestamp: new Date().toISOString(),
        }),
    };
};
