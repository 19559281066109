import useAuth from '@hooks/useAuth';
import { captureException } from '@sentry/react';
import { CustomError } from 'lib/global/CustomError';

import { isGQLErrors } from 'apis/types';

import { useSettings } from 'hooks/useSettings';

/**
 * This is a fetcher function which we provide for the generated
 * graphql hooks in reactQueryHooks.ts.
 */
export function useFetchData<TData, TVariables>(
    query: string
): (variables?: TVariables) => Promise<TData> {
    const { accessToken } = useAuth();
    const { listEndpoint, listClientId } = useSettings();

    return async (variables?: TVariables) => {
        try {
            const res = await fetch(listEndpoint, {
                method: 'POST',
                body: JSON.stringify({ query, variables }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'X-Client-Id': listClientId,
                },
            });

            if (!res.ok) {
                return Promise.reject(
                    new CustomError(
                        'FavouritesError',
                        'An unknown error occurred.'
                    )
                );
            }
            const json = await res.json();
            const { data, errors } = json || { data: null, errors: null };

            if (!data && isGQLErrors(errors)) {
                const { extensions, message } = errors[0];
                captureException(`${message}: ${extensions.code}`);
                return Promise.reject(
                    new CustomError('FavouritesError', message, extensions.code)
                );
            }
            return data;
        } catch (error) {
            let message = 'An unknown error occurred.';
            if (error instanceof Error) {
                message = error.message;
            }
            const customError = new CustomError('FavouritesError', message);
            console.error(message);
            captureException(customError);
            return Promise.reject(customError);
        }
    };
}
