import { EventPayloadCreators } from '../eventTypes';
import { createStoreIntentPayload } from './createStoreIntentPayload';

import { List } from 'apis/types';

/** Contains the implementations for all events defined as functions which return a payload. */
export const eventPayloadCreators: EventPayloadCreators = {
    favourites: {
        // Returns list id and list value local i.e total unit price of the items in the list
        list_value: (list?: List) => ({
            custom: {
                list_id: list ? list.listId : '',
                list_value: list
                    ? list.totals?.prices?.online?.regular?.totalExclDiscount.exclTax.toFixed(
                          2
                      ) || '0'
                    : 'NaN',
            }, // list.retail.regularTotalPrice?.totalInclDiscount.exclTax
        }),
        store_intent: createStoreIntentPayload,
    },
    ecommerce: {
        wishlist_increase_amount: ({
            listId,
            itemNo,
            itemType,
            quantityDelta,
        }) => ({
            custom: {
                list_id: listId,
                item_number_event: itemNo,
                item_type_event: itemType,
                product_quantity: quantityDelta,
            },
        }),
        wishlist_decrease_amount: ({
            listId,
            itemNo,
            itemType,
            quantityDelta,
        }) => ({
            custom: {
                list_id: listId,
                item_number_event: itemNo,
                item_type_event: itemType,
                product_quantity: quantityDelta,
            },
        }),
        cross_off_checklist: ({ name, itemNo, listId, itemType }) => ({
            event_label: productId(name, itemNo),
            custom: {
                list_id: listId,
                item_number_event: itemNo,
                item_type_event: itemType,
            },
        }),
        remove_list: ({ quantity, listId }) => ({
            event_label: String(quantity),
            custom: { list_id: listId },
        }),
        wishlist_store_mode: ({ listId, storeId }) => ({
            custom: {
                list_id: listId,
                ...(storeId && { local_store: storeId }),
            },
        }),
        wishlist_online_mode: ({ listId }) => ({
            custom: {
                list_id: listId,
            },
        }),
        view_list: ({ view, listId, list }) => ({
            event_label: view === 'store' ? 'buy_in_store' : 'shop_online',
            custom: {
                list_id: listId,
                list_value: list
                    ? list.totals?.prices?.online?.regular?.totalExclDiscount.exclTax.toFixed(
                          2
                      ) || '0'
                    : 'NaN',
                ...(list?.items &&
                    list.items.reduce(
                        (acc, curr) => ({
                            item_number_event: concat(
                                acc.item_number_event,
                                `${curr.itemNo}`
                            ),
                            product_quantity: concat(
                                acc.product_quantity,
                                `${curr.quantity}`
                            ),
                            item_type_event: concat(
                                acc.item_type_event,
                                `${curr.type}`
                            ),
                        }),
                        {
                            item_number_event: '',
                            product_quantity: '',
                            item_type_event: '',
                        }
                    )),
            },
        }),
    },
};

const concat = (acc: string, curr: string) =>
    acc === '' ? curr : `${acc},${curr}`;

// Analytics wants a product identifier in the form "product_name product_id"
// for example "ARKELSTORP 30260807"
const productId = (name: string, itemNo: string) => name + ' ' + itemNo;
