import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { OptimizelyContextType, Wrapper } from './types';
import { useSettings } from '@hooks/useSettings';
import { shouldEnableAllExperiments } from 'lib/global';

type OptimizelyProviderProps = {
    children: React.ReactNode;
};

// View more options: https://github.com/ingka-group-digital/abtesting-fragment#init
const options = {
    timeout: Infinity,
};

export const OptimizelyContext = createContext<OptimizelyContextType>({
    activateExperiments: () => undefined,
    decide: () => undefined,
    trackEvent: () => undefined,
    isExperimentOn: () => false,
    getVariationKey: () => '',
    isExperimentOnSecondary: () => false,
});

export const OptimizelyProvider: React.FC<OptimizelyProviderProps> = ({
    children,
}) => {
    const { optimizelyKey, optimizelyKeySecondary } = useSettings();
    const [wrapper, setWrapper] = useState<Wrapper | null>(null);
    // Secondary wrapper used for cross web experiments
    const [secondaryWrapper, setSecondaryWrapper] = useState<Wrapper | null>(
        null
    );
    const decisionCache = useRef<Record<string, ReturnType<Wrapper['decide']>>>(
        {}
    );
    const secondaryDecisionCache = useRef<
        Record<string, ReturnType<Wrapper['decide']>>
    >({});

    useEffect(() => {
        const init = async () => {
            if (
                (wrapper && secondaryWrapper) ||
                !optimizelyKey ||
                !optimizelyKeySecondary
            ) {
                return;
            }
            try {
                if (!window.ikea?.experiment?.init) {
                    console.warn('missing window.ikea.experiment.init');
                    return;
                }
                const _wrapper = await window.ikea.experiment.init(
                    optimizelyKey,
                    options
                );
                const _secondaryWrapper = await window.ikea.experiment.init(
                    optimizelyKeySecondary,
                    options
                );
                setWrapper(_wrapper);
                setSecondaryWrapper(_secondaryWrapper);
            } catch {
                // Fail silently.
            }
        };

        init();
        window.ikea?.pubsub?.subscribe('ikeaCookieConsent/changed', init);
        return () => {
            window.ikea?.pubsub?.unsubscribe('ikeaCookieConsent/changed', init);
        };
    }, [optimizelyKey, wrapper, secondaryWrapper, optimizelyKeySecondary]);

    const decide = useCallback(
        (decisionKey: string) => {
            const cached = decisionCache.current[decisionKey];
            if (cached) {
                return cached;
            }
            const decision = wrapper?.decide?.(decisionKey);
            if (decision) {
                decisionCache.current[decisionKey] = decision;
            }
            return decision;
        },
        [wrapper]
    );

    const decideSecondary = useCallback(
        (decisionKey: string) => {
            const cached = secondaryDecisionCache.current[decisionKey];
            if (cached) {
                return cached;
            }
            const decision = secondaryWrapper?.decide?.(decisionKey);
            if (decision) {
                secondaryDecisionCache.current[decisionKey] = decision;
            }
            return decision;
        },
        [secondaryWrapper]
    );

    const ctx = useMemo<OptimizelyContextType>(
        () => ({
            activateExperiments: () => undefined,
            trackEvent: (
                eventName: string,
                attributes?: Record<string, unknown>,
                eventTags?: Record<string, unknown>
            ) => {
                try {
                    wrapper?.track?.(eventName, attributes, eventTags);
                } catch {
                    // Fail silently.
                }
            },
            decide: (decisionKey: string) => {
                try {
                    return decide(decisionKey);
                } catch {
                    // Fail silently.
                }
            },
            isExperimentOn: (experimentKey: string): boolean => {
                try {
                    return (
                        shouldEnableAllExperiments ||
                        decide(experimentKey)?.variationKey === 'on'
                    );
                } catch {
                    return false;
                }
            },
            getVariationKey: (experimentKey: string): string => {
                try {
                    return decide(experimentKey)?.variationKey || '';
                } catch {
                    return '';
                }
            },
            isExperimentOnSecondary: (experimentKey: string): boolean => {
                try {
                    return (
                        shouldEnableAllExperiments ||
                        ['on', 'b'].includes(
                            decideSecondary(experimentKey)?.variationKey || ''
                        )
                    );
                } catch {
                    return false;
                }
            },
        }),
        [decide, decideSecondary, wrapper]
    );

    return (
        <OptimizelyContext.Provider value={ctx}>
            {children}
        </OptimizelyContext.Provider>
    );
};
