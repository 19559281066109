import { createContext, PropsWithChildren, useMemo } from 'react';

import {
    createEcommerceEventHandler,
    createEventHandler,
    createLogEvent,
} from './helpers';
import useLocale from '@hooks/useLocale';

type AnalyticsContextType = {
    logEvents: ReturnType<typeof createEventHandler>;
    logEvent: ReturnType<typeof createLogEvent>;
    logEcommerceEvent: ReturnType<typeof createEcommerceEventHandler>;
    logUpdateQuantity: (
        items: {
            itemNo: string;
            quantityDelta: number;
            itemType: string;
        }[],
        listId: string
    ) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextType>({
    logEvents: () => undefined,
    logEvent: () => undefined,
    logEcommerceEvent: () => undefined,
    logUpdateQuantity: () => undefined,
});

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
    const { country, language } = useLocale();
    const ctx = useMemo(() => {
        const logEvents = createEventHandler({ country, language });
        const logEvent = createLogEvent(logEvents);
        const logEcommerceEvent = createEcommerceEventHandler({
            country,
            language,
        });

        const logUpdateQuantity = (
            items: {
                itemNo: string;
                quantityDelta: number;
                itemType: string;
            }[],
            listId: string
        ) => {
            items.forEach(({ quantityDelta, itemNo, itemType }) => {
                logEvent(
                    'ecommerce',
                    quantityDelta > 0
                        ? 'wishlist_increase_amount'
                        : 'wishlist_decrease_amount',
                    {
                        listId,
                        itemNo,
                        itemType,
                        quantityDelta,
                    }
                );
            });
        };

        return {
            logEvent,
            logEvents,
            logEcommerceEvent,
            logUpdateQuantity,
        };
    }, [country, language]);

    return (
        <AnalyticsContext.Provider value={ctx}>
            {children}
        </AnalyticsContext.Provider>
    );
};
