import { Translations } from './types';
import { MarketData } from '@utils/getMarketData';

export const fetchTranslations = async ({
    language,
    country,
}: MarketData): Promise<Translations> => {
    const query = new URLSearchParams({
        language,
        country: country.toUpperCase(),
    });
    const url = `${
        window.location.origin
    }/${country}/${language}/favourites/api/translations?${query.toString()}`;
    const res = await fetch(url);
    return await res.json();
};
