import { createContext, PropsWithChildren, useMemo } from 'react';

import { createTranslate } from './createTranslate';
import { fetchTranslations } from './fetchTranslations';
import { ITranslate, Translations } from './types';
import useLocale from '@hooks/useLocale';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

export const TranslationsContext = createContext<ITranslate>(key => key);

export const TranslationsContextProvider = ({
    children,
}: PropsWithChildren) => {
    const { query } = useRouter();
    const shouldShowPhraseKeys = query.showPhraseKeys !== undefined;
    const localeData = useLocale();
    const { data: translations } = useQuery<Translations>(
        ['translations', localeData.ikeaMarketName],
        async () => fetchTranslations(localeData),
        {
            staleTime: Infinity, // Always consider translations data to be fresh in favor of SSR
        }
    );

    const translate = useMemo(
        () =>
            createTranslate({
                locale: localeData.locale,
                shouldShowPhraseKeys,
                translations,
            }),
        [localeData.locale, shouldShowPhraseKeys, translations]
    );
    return (
        <TranslationsContext.Provider value={translate}>
            {children}
        </TranslationsContext.Provider>
    );
};
