import { useMemo } from 'react';

import { CommonVariables } from './types';
import useUserPreferences from 'context/UserPreferences';

import useLocale from 'hooks/useLocale';

// This hook can be used by the operations to get common variables so they won't need to be supplied for each hook.
const useGetCommonVariables = (): CommonVariables => {
    const { language: languageCode } = useLocale();
    const { preferredStore: storeId = null, preferredPostalCode } =
        useUserPreferences();
    const withStore = !!storeId;
    const zip = preferredPostalCode ? preferredPostalCode.postalCode : null;

    return useMemo(
        () => ({
            languageCode,
            storeId,
            withStore,
            zip,
        }),
        [languageCode, zip, storeId, withStore]
    );
};

export default useGetCommonVariables;
